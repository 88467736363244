import {NumberTrackingRptConstants} from '../constants';

export function NumberTrackingRpt(state = {}, action) {   
    switch (action.type) {
        case NumberTrackingRptConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case NumberTrackingRptConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case NumberTrackingRptConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
            default:
                return state
        }
        
    }
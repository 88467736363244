import { userService } from '../services';
import { userConstants } from '../constants';
import { alertActions } from '.';
import { history } from '../helpers';

export const userMasterActions = {
    getAll,
    createUser,
    updateUser,
    inActiveRow,
    onHideShow
};

function getAll(obj) {
    return dispatch => {
        dispatch(request());

        userService.fetchUserData(obj).then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(datas) { return { type: userConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function createUser(data) {
    return dispatch => {
        dispatch(request(data));

        userService.createUser(data).then(
            response => {
                //history.push('/');
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(onHideShow(false));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: userConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: userConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: userConstants.CREATE_FAILURE, error } }
};

function updateUser(data) {
    return dispatch => {
        dispatch(request(data));

        userService.updateUser(data).then(
            response => {
                //history.push('/');                
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(data));
                        dispatch(onHideShow(false));
                        dispatch(alertActions.success(response.ResponseMessage))
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: userConstants.UPDATE_REQUEST, data } }
    function success(data) { return { type: userConstants.UPDATE_SUCCESS, data } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
};


function inActiveRow(data) {
    ;
    return dispatch => {
        dispatch(request(data));

        userService.inActiveRow(data).then(
            response => {
                dispatch(success(data));
                //history.push('/');                
                if (response && response.ResponseCode) {
                    response.ResponseCode == "200" ? dispatch(alertActions.success(response.ResponseMessage))
                        : response.ResponseCode == "400" ? dispatch(alertActions.warning(response.ResponseMessage))
                            : dispatch(alertActions.error(response.ResponseMessage))
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: userConstants.DELETE_REQUEST, data } }
    function success(data) { return { type: userConstants.DELETE_SUCCESS, data } }
    function failure(error) { return { type: userConstants.DELETE_FAILURE, error } }
};

function onHideShow(isbool) {
    return {
        type: userConstants.DIALOG_REQUEST, isbool
    }
}


import { QRDetailsRptService } from '../services';
import { QRDetailsRptConstants } from '../constants';
import { alertActions } from '.';

export const QRDetailsRptActions = {

    exportData,
    lodingData,
    getReport
};

function getReport(obj) {
    debugger
    return dispatch => {
        dispatch(request());

        QRDetailsRptService.getReport(obj).then(response => {
            debugger
            dispatch(lodingData(false));;
            dispatch(success(response.ResponseData))
            //console.log(response.ResponseData);
        },
            error => {
                dispatch(lodingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }

        );
    };
    function request() { return { type: QRDetailsRptConstants.GETALL_REQUEST } }
    function success(datas) { return { type: QRDetailsRptConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: QRDetailsRptConstants.GETALL_FAILURE, error } }
}

function exportData(obj) {
    return dispatch => {
        dispatch(request());
        QRDetailsRptService.getReport(obj).then(response => {
            dispatch(lodingData(false));
            var data = response.ResponseData;
            if (data != null) {
                data.forEach(function (v) {
                    delete v.TotalRows;
                });
            }
            dispatch(success(data))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };
    function request() { return { type: QRDetailsRptConstants.GETEXPORT_REQUEST } }
    function success(datas) { return { type: QRDetailsRptConstants.GETEXPORT_SUCCESS, datas } }
    function failure(error) { return { type: QRDetailsRptConstants.GETEXPORT_FAILURE, error } }
}

function lodingData(isbool) {
    return {
        type: QRDetailsRptConstants.LODING_REQUEST, isbool
    }
}


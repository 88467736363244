// request for lazy loading for all pages
export default class tableRequest {
    IsActive = true;
    PageNo = 1;
    First = 1;
    SearchValue = "";
    PageSize = 10;
    SortColumn = "CreatedDt";
    SortOrder = "DESC";
    FromDate = "";
    ToDate = "";
    VM = 0;
}
import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Button } from "primereact/button";
import { Column } from 'primereact/column';
import TextField from '@material-ui/core/TextField';
import { Calendar } from 'primereact/calendar';
import { alertActions, mappingRptActions } from "../../actions/index.js";
import { connect } from 'react-redux';
import tableRequest from '../../models/tableRequest.js';
import { Toast } from 'primereact/toast';
import { CSVLink } from "react-csv";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";

class Out extends Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        this.onPage = this.onPage.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.csvLink = React.createRef();
        this.onSort = this.onSort.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.FilterData = this.FilterData.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.state = {
            loading: false,
            FromDate: new Date(),
            ToDate: new Date(),
            first: 0,
            rows: 5,
            totalRecords: 0,
            globalFilter: null,
            visible: false,
            sortOrder: '',
            datalist: [],
            headers: [
                { label: "From No", key: "FromNo" },
                { label: "To No", key: "ToNo" },
                { label: "Issued Qty", key: "Qty" },
                { label: "Issued On", key: "CreatedDt" },
                { label: "Consumed Qty", key: "ConsumedQty" }
            ]
        }
    }

    componentDidMount() {
        this.props.lodingData(true);
        var date = new Date();
        date.setDate(date.getDate() - 7);
        this.tblResponse.FromDate = date.toLocaleDateString();
        this.tblResponse.ToDate = new Date().toLocaleDateString();
        this.setState({
            FromDate: date,
            ToDate: new Date()
        });
        this.props.fetchApi(this.tblResponse);
    }

    GetMapping() {
        this.props.lodingData(true);
        let ToDt = new Date();
        let FrmDt = new Date();
        FrmDt.setDate(ToDt.getDate() - 7);

        const myFmDt = this.getCurrentDateYYYYMMDD(FrmDt);
        const myToDt = this.getCurrentDateYYYYMMDD(ToDt);

        this.tblResponse.FromDate = myFmDt.toLocaleDateString();
        this.tblResponse.ToDate = myToDt.toLocaleDateString();
        this.props.fetchApi(this.tblResponse);
    }

    FilterData() {
        this.props.lodingData(true);
        this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
        this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
        this.props.fetchApi(this.tblResponse);
    }

    getCurrentDateYYYYMMDD(dt) {
        var dd = String(dt.getDate()).padStart(2, '0');
        var mm = String(dt.getMonth() + 1).padStart(2, '0');
        var yyyy = dt.getFullYear();

        dt = yyyy + '/' + mm + '/' + dd;
        return dt;
    }

    handleChange(event) {
        const { name, value } = event.target;
        switch (name) {
            case 'FromDate':
                if (value === null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please select from date.', life: 3000 });
                    return;
                }
                else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
                    this.formIsValid = false;
                    // this.refs.Messchild.showError("Please enter only date.");
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please enter only date.', life: 3000 });
                    return;
                }
                else if ((value instanceof Date) && (this.state.ToDate instanceof Date)) {
                    const startDate = value;
                    const endDate = this.state.ToDate;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        // this.refs.Messchild.showError("From date must be less than to date.");
                        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'From date must be less than to date.', life: 3000 });
                        return;
                    }
                }

                break;
            case 'ToDate':
                if (value === null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please select to date.', life: 3000 });
                    // this.refs.Messchild.showError("Please select to date.");
                    return;
                }
                else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
                    this.formIsValid = false;
                    // this.refs.Messchild.showError("Please enter only date.");
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please enter only date.', life: 3000 });
                    return;
                }
                else if ((value instanceof Date) && (this.state.FromDate instanceof Date)) {
                    const startDate = this.state.FromDate;
                    const endDate = value;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'To date must be greater than from date.', life: 3000 });
                        return;
                    }
                }

                break;
            default:
                break;
        }
        this.setState({ [name]: value });
    }

    //to lazy load
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
        this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
        this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
    }

    onPage(event) {
        this.props.lodingData(true);
        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
        }, 250);
    }

    //to search
    searchFilter(e) {
        this.props.lodingData(true);
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);
    }

    //to sort
    onSort(event) {
        this.props.lodingData(true);
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.multiSortMeta[0].field === "CreatedDate" ? "CreatedDt" : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';//this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mappingList !== this.props.mappingList) {
            // this.props.myProp has a different value
            if (this.props.mappingList) {
                var e = this.props.mappingList;
                this.setState({
                    totalRecords: e[0].TotalRows
                });
            } else {
                this.setState({
                    totalRecords: 0
                });
            }
        }
        debugger
        if (prevProps.ExportList !== this.props.ExportList) {
            if (this.props.ExportList.length > 0) {
                if (this.state.exportitems) {
                    this.csvLink.current.element.click();
                    this.setState({ exportitems: false });
                }
            }
        }
    }

    exportCSV() {
        debugger
        this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
        this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
        this.tblResponse.First = -1;
        this.tblResponse.PageSize = -1;
        this.setState({ exportitems: true });
        this.props.exportData(this.tblResponse);
    }
    render() {
        return (
            <div className="datatable-filter-demo">
                <Toast ref={(el) => this.toast = el} />
                <div className="row mb-3">
                    <div className="col-md-3">
                        <h4>Issued Vs Consumed Report</h4>
                        <span style={{ display: 'block', fontSize: '17px' }}>
                            Total Issued Quantity: {this.props.mappingList && this.props.mappingList.length > 0 ?
                                (this.props.mappingList[0].TotalMappedQty > 0 ? this.props.mappingList[0].TotalMappedQty : '0') : '0'}
                        </span>
                        <span style={{ display: 'block', fontSize: '17px' }}>Total Consumed Quantity: {this.props.mappingList && this.props.mappingList.length > 0 ?
                            (this.props.mappingList[0].TotalConsumedQty > 0 ? this.props.mappingList[0].TotalConsumedQty : '0') : '0'}
                        </span>
                    </div>
                    <div className="col-md-9">
                        <div className="row text-right">
                            <div className="col-md-4"></div>
                            <div className="col-md-3">
                                <Calendar placeholder="From Date" name="FromDate" showIcon="true" value={this.state.FromDate} onChange={this.handleChange}></Calendar></div>
                            <div className="col-md-3">
                                <Calendar placeholder="To Date" name="ToDate" showIcon="true" value={this.state.ToDate} onChange={this.handleChange}></Calendar>
                            </div>
                            <div className="col-md-2 text-left">
                                <button type="button" onClick={this.FilterData} className="btn btn-primary p-2 pl-2 pr-2 mr-2">Submit</button>
                                <button onClick={this.exportCSV} className="btn btn-success p-2 pl-3 pr-3">Export</button>

                            </div>
                            {/* <div className="col-md-3">
                                <TextField id="standard-basic" size="small" variant="outlined" className="fullwidth" label="Search" onInput={this.searchFilter} />
                            </div> */}

                        </div>

                    </div>
                </div>

                <div className="text-center">
                    <ExcelFile
                        filename={"Issued Vs Consumed Report " + this.state.FromDate.toLocaleDateString() + "-" + this.state.ToDate.toLocaleDateString()}
                        element={<Button hidden ref={this.csvLink} />}
                    >
                        <ExcelSheet data={this.props.ExportList} name={"Issued Vs Consumed Report"}>
                            <ExcelColumn label="From No" value="FromNo" />
                            <ExcelColumn label="To No" value="ToNo" />
                            <ExcelColumn label="Issued Qty" value="Qty" />
                            <ExcelColumn label="Issued On" value="CreatedDt" />
                            <ExcelColumn label="Consumed Qty" value="ConsumedQty" />
                        </ExcelSheet>
                    </ExcelFile>
                </div>


                <div className="card">
                    <DataTable value={this.props.mappingList}
                        scrollable responsive={true}
                        globalFilter={this.state.globalFilter}
                        emptyMessage="No data found." sortMode="multiple" paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[5, 10, 25, 100, parseInt(this.state.totalRecords)]}
                        totalRecords={this.state.totalRecords} first={this.state.first}
                        loading={this.props.loading} ref={(el) => { this.dt = el; }}>
                        <Column field="FromNo" header="From No" sortable></Column>
                        <Column field="ToNo" disabled header="To No" sortable></Column>
                        <Column field="Qty" disabled header="Issue Qty" sortable></Column>
                        <Column field="CreatedDt" header="Issue On" sortable></Column>
                        <Column field="ConsumedQty" header="Consumed Qty" sortable></Column>
                    </DataTable>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        mappingList: state.mappingRpt.items,
        ExportList: state.mappingRpt.exportitems == undefined ? [] : state.mappingRpt.exportitems,
        loading: state.mappingRpt.loading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (obj) => {
            dispatch(mappingRptActions.getOUT(obj))
        },
        exportData: (obj) => dispatch(mappingRptActions.exportData(obj)),
        lodingData: (isLoding) => dispatch(mappingRptActions.lodingData(isLoding))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Out)

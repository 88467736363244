import React, { Component } from 'react'
import Header from './Header';
import Footer from './Footer';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Dashboard from './../views/Dashboard';
import ProductMaster from './../components/masters/productMaster';
import roleMaster from './../components/masters/roleMaster';
import Profile from './../views/Profile';
import IssueNumber from '../components/mapping/IssueNumber';
import Mapping from './../components/transactions/Mapping';
import DeMapping from './../components/transactions/DeMapping';
import Damage from './../components/transactions/Damage';
import UserMaster from './../components/masters/userMaster';
import VerificationLog from './../components/reports/VerificationLog';
import MappingRpt from './../components/reports/MappingRpt';
import FeedbackRpt from './../components/reports/FeedbackRpt';
import ProductConfiguration from '../components/masters/ProductConfiguration';
import numberGenerationRpt from '../components/reports/numberGenerationRpt';
import numberTrackingRpt from '../components/reports/numberTrackingRpt';
import DamageRpt from "../components/reports/DemageRpt";
import Out from '../components/reports/Out';
import QRDetailsRpt from '../components/reports/QRDetailsRpt';
export default class Template extends Component {
    render() {
        return (
            <div className="row">
                <Header />
                <div className="col-md-12">
                    <Router>
                        <Switch>
                            <Route exact path="/dashboard" component={Dashboard} />
                            <Route exact path="/profile" component={Profile} />
                            <Route exact path="/masters/product-master" component={ProductMaster} />
                            <Route exact path="/masters/role-master" component={roleMaster} />
                            <Route exact path="/masters/user-master" component={UserMaster} />
                            <Route exact path="/masters/product-configuration" component={ProductConfiguration} />
                            <Route exact path="/activation/issue-number" component={IssueNumber} />
                            <Route exact path="/transactions/damage" component={Damage} />
                            <Route exact path="/transactions/mapping" component={Mapping} />
                            <Route exact path="/transactions/de-mapping" component={DeMapping} />
                            <Route exact path="/reports/verification-log" component={VerificationLog} />
                            <Route exact path="/reports/mapping-report" component={MappingRpt} />
                            <Route exact path="/reports/issuedvsconsumed-report" component={Out} />
                            <Route exact path="/reports/feedback-report" component={FeedbackRpt} />
                            <Route exact path="/reports/qrdetails-report" component={QRDetailsRpt} />
                            <Route exact path="/reports/numberGeneration-report" component={numberGenerationRpt} />
                            <Route exact path="/reports/numberTracking-report" component={numberTrackingRpt} />
                            <Route exact path="/reports/Damage-report" component={DamageRpt} />
                            <Route path="*" component={Dashboard} />
                        </Switch>
                    </Router>
                </div>
                <div className="col-md-12">
                    <Footer />
                </div>
            </div>
        )
    }
}

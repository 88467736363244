import { verificationLogConstants } from '../constants';

export function verification(state = {}, action) {
    switch (action.type) {
        case verificationLogConstants.LODING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };

        case verificationLogConstants.GETEXPORT_REQUEST:
            return {
                ...state,
                exporting: true,
            };
        case verificationLogConstants.GETEXPORT_SUCCESS:
            return {
                ...state,
                exportitems: action.datas,
            };
        case verificationLogConstants.GETEXPORT_FAILURE:
            return {
                ...state,
                error: action.error,
            };



        case verificationLogConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case verificationLogConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case verificationLogConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }


}
export const QRDetailsRptConstants = {
    GETALL_REQUEST: 'QRDATA_GETALL_REQUEST',
    GETALL_SUCCESS: 'QRDATA_GETALL_SUCCESS',
    GETALL_FAILURE: 'QRDATA_GETALL_FAILURE',

    GETEXPORT_REQUEST: 'QRDATA_GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'QRDATA_GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'QRDATA_GETEXPORT_FAILURE',



    LODING_REQUEST: 'LODING_REQUEST'
}
import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TextField from '@material-ui/core/TextField';
import { Calendar } from 'primereact/calendar';
import { Button } from "primereact/button";
import { alertActions, verificationLogActions } from "../../actions";
import { connect } from 'react-redux';
import tableRequest from '../../models/tableRequest.js';
import { Toast } from 'primereact/toast';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CsvDownloader from 'react-csv-downloader';
import { CSVLink } from "react-csv";
import { el } from 'date-fns/locale';
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";


class VerificationLog extends Component {
    constructor(props) {
        super(props);
        this.csvLink = React.createRef();
        this.tblResponse = new tableRequest();
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.FilterData = this.FilterData.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.state = {
            loading: false,
            FromDate: new Date(),
            ToDate: new Date(),
            first: 0,
            rows: 10,
            totalRecords: 0,
            globalFilter: null,
            visible: false,
            sortOrder: '',
            datalist: [],
            headers: [
                { label: "Part Number", key: "PartNo" },
                { label: "Part Description", key: "PartDescription" },
                { label: "Sequence No", key: "SeqNo" },
                { label: "Result Message", key: "StatusResult" },
                { label: "Scan Count", key: "ScanCount" },
                { label: "Mobile Number", key: "MobileNo" },
                { label: "Verification Mode", key: "VerificationMode" },
                { label: "Verified Date", key: "VerifiedDate" },
            ]
        }
    }
    componentDidMount() {
        this.props.lodingData(true);
        var date = new Date();
        date.setDate(date.getDate() - 7);
        this.tblResponse.FromDate = date.toLocaleDateString();
        this.tblResponse.ToDate = new Date().toLocaleDateString();
        this.tblResponse.SortColumn = "VerifiedDate";
        this.setState({
            FromDate: date,
            ToDate: new Date()
        });
        this.props.fetchApi(this.tblResponse);

    }

    onChange = date => this.setState({ FromDate: '', ToDate: '' })

    FilterData() {
        debugger
        this.props.lodingData(true);
        this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
        this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
        this.props.fetchApi(this.tblResponse);

    }

    getCurrentDateYYYYMMDD(dt) {
        var dd = String(dt.getDate()).padStart(2, '0');
        var mm = String(dt.getMonth() + 1).padStart(2, '0');
        var yyyy = dt.getFullYear();

        dt = yyyy + '/' + mm + '/' + dd;
        return dt;
    }

    handleChange(event) {
        const { name, value } = event.target;
        switch (name) {
            case 'FromDate':
                if (value === null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    // this.refs.Messchild.showError("Please select from date.");
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please select from date.', life: 3000 });

                    return;
                }
                else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
                    this.formIsValid = false;
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please enter only date.', life: 3000 });
                    // this.refs.Messchild.showError("Please enter only date.");
                    return;
                }
                else if ((value instanceof Date) && (this.state.ToDate instanceof Date)) {
                    const startDate = value;
                    const endDate = this.state.ToDate;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'From date must be less than to date.', life: 3000 });
                        // this.refs.Messchild.showError("From date must be less than to date.");
                        return;
                    }
                }

                break;
            case 'ToDate':
                if (value === null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please select to date.', life: 3000 });
                    // this.refs.Messchild.showError("Please select to date.");
                    return;
                }
                else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
                    this.formIsValid = false;
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please enter only date.', life: 3000 });
                    // this.refs.Messchild.showError("Please enter only date.");
                    return;
                }
                else if ((value instanceof Date) && (this.state.FromDate instanceof Date)) {
                    const startDate = this.state.FromDate;
                    const endDate = value;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'To date must be greater than from date.', life: 3000 });
                        // this.refs.Messchild.showError("To date must be greater than from date.");
                        return;
                    }
                }

                break;
            default:
                break;
        }

        this.setState({ [name]: value });
    }

    //to lazy load
    getLazyLoadingValues(event) {

        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        this.tblResponse.SortColumn = event.sortField == undefined ? "VerifiedDate" : event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
        this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
        this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
    }

    onPage(event) {
        this.props.lodingData(true);

        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);

        }, 250);
    }

    //to search
    searchFilter(e) {
        this.props.lodingData(true);
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);

    }
    onModeChange(e) {
        this.tblResponse.VM = e.target.value;
    }

    //to sort
    onSort(event) {
        this.props.lodingData(true);
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.multiSortMeta[0].field === "VerifiedDate" ? "VerifiedDate" : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';//this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);

    }

    componentDidUpdate(prevProps) {
        if (prevProps.verificationList !== this.props.verificationList) {
            // this.props.myProp has a different value
            if (this.props.verificationList) {
                var e = this.props.verificationList;
                this.setState({
                    totalRecords: e[0].TotalRows
                });
            } else {
                this.setState({
                    totalRecords: 0
                });
            }
        }
    }

    exportCSV() {
        this.tblResponse.FromDate = this.state.FromDate;
        this.tblResponse.ToDate = this.state.ToDate;
        this.tblResponse.First = -1;
        this.tblResponse.PageSize = -1;
        this.props.exportData(this.tblResponse);
        setTimeout(() => {
            this.csvLink.current.element.click();
        }, 10000);
    }

    render() {

        return (
            <div className="datatable-filter-demo">
                {/* <ToastContainer /> */}
                <Toast ref={(el) => this.toast = el} />
                <div className="row mb-3">
                    <div className="col-md-3">
                        <h4>Verification Report</h4>
                    </div>
                    <div className="col-md-9">
                        <div className="row text-right">
                            <div className="col-md-2">
                                <FormControl variant="outlined" size="small" className="formControl col-md-12">
                                    <InputLabel id="demo-simple-select-outlined-label">Select Scan Type</InputLabel>
                                    <Select onChange={(e) => { this.handleChange(e); this.onModeChange(e); }} style={{ width: "10vw" }} name="ProductId" value={this.state.ProductId} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select Product">
                                        {/* <MenuItem><em>Select</em></MenuItem>    */}
                                        <MenuItem value="0">All</MenuItem>
                                        <MenuItem value="1">Mobile App</MenuItem>
                                        <MenuItem value="2">SMS</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-2">
                                <Calendar placeholder="From Date" showIcon="true" name="FromDate" value={this.state.FromDate} onChange={this.handleChange} appendTo={document.getElementById('root')} ></Calendar></div>
                            <div className="col-md-2">
                                <Calendar placeholder="To Date" showIcon="true" name="ToDate" value={this.state.ToDate} onChange={this.handleChange} appendTo={document.getElementById('root')} ></Calendar>
                            </div>
                            <div className="col-md-3 text-left">
                                <button type="button" onClick={this.FilterData} className="btn btn-primary p-2 pl-3 pr-3 mr-2">Submit</button>

                                {/* <CSVLink data={this.props.List} headers={this.state.headers} filename={"Verification Report.csv"}
                                    // className="btn btn-primary ml-2"
                                    target="_blank"> */}
                                <button onClick={this.exportCSV} className="btn btn-success p-2 pl-3 pr-3">Export</button>
                                {/* </CSVLink> */}
                            </div>
                            <div className="col-md-3">
                                <TextField id="standard-basic" size="small" variant="outlined" className="fullwidth" label="Search" onInput={this.searchFilter} />
                            </div>

                        </div>

                    </div>
                </div>

                <div className="text-center">
                    <ExcelFile
                        filename={"Verification Report"}
                        element={<Button hidden ref={this.csvLink} />}
                    >
                        <ExcelSheet data={this.props.List} name={"Verification Report"}>
                            <ExcelColumn label="Part No" value="PartNo" />
                            <ExcelColumn label="Part Description" value="PartDescription" />
                            <ExcelColumn label="Sequence No" value="SeqNo" />
                            <ExcelColumn label="Result Message" value="StatusResult" />
                            <ExcelColumn label="Scan Count" value="ScanCount" />
                            <ExcelColumn label="Mobile Number" value="MobileNo" />
                        </ExcelSheet>
                    </ExcelFile>
                    {/* <CSVLink
                  hidden
                  data={this.props.List}
                  headers={this.state.headers}
                  filename={"User Report.csv"}
                  target="_blank"
                >
                  <Button hidden ref={this.csvLink} /> 
                </CSVLink> */}
                </div>

                <div className="card">
                    <DataTable value={this.props.verificationList}
                        scrollable responsive={true}
                        globalFilter={this.state.globalFilter}
                        emptyMessage="No data found." sortMode="multiple" paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        style={{ width: '100%' }} rowsPerPageOptions={[10, 25, 50, 100, parseInt(this.state.totalRecords)]}
                        loading={this.props.loding}
                        rows={this.state.rows}
                        totalRecords={this.state.totalRecords}
                        lazy={true} first={this.state.first}
                        onPage={this.onPage} onSort={this.onSort}
                        ref={(el) => { this.dt = el; }}>
                        <Column field="PartNo" disabled header="Part Number" sortable></Column>
                        <Column field="PartDescription" disabled header="Part Description" sortable></Column>
                        <Column field="SeqNo" disabled header="Sequence No" sortable></Column>
                        <Column field="StatusResult" disabled header="Result Message" sortable></Column>
                        <Column field="ScanCount" disabled header="Scan Count" sortable></Column>
                        <Column field="VerificationMode" disabled header="Verification Mode" sortable></Column>
                        <Column field="MobileNo" header="Mobile No" sortable></Column>
                        <Column field="VerifiedDate" disabled header="Verified Date" sortable></Column>
                        {/* <Column field="VerifiedBy" header="Verified By" sortable></Column> */}
                    </DataTable>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        verificationList: state.verification.items,
        List: state.verification.exportitems == undefined ? [] : state.verification.exportitems,
        loding: state.verification.loding
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        // fetchProductDetails: (ProductCode) => {
        //     dispatch(fgTraceabilityReportActions.getFGTraceabilityReport(ProductCode))
        // },
        // fetchIntermediateDetails: (ProductCode) => {
        //     dispatch(fgTraceabilityReportActions.getIntermediateTraceability(ProductCode))
        // },
        fetchApi: (obj) => {
            dispatch(verificationLogActions.getAll(obj))
        },
        exportData: (obj) => dispatch(verificationLogActions.exportData(obj)),
        lodingData: (isLoding) => dispatch(verificationLogActions.lodingData(isLoding))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerificationLog)

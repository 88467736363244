import { authHeader, handleResponse } from '../helpers';
export const mappingRptService = {
    fetchMappingData,
    fetchOUTData,
    fetchData,
   
};

function fetchMappingData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}MappingRpt/GetMappingReport`, requestOptions)
        .then(handleResponse);
};
function fetchOUTData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}MappingRpt/GetOUTReport`, requestOptions)
        .then(handleResponse);
};
function fetchData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}MappingRpt/GetReport`, requestOptions)
        .then(handleResponse);
}
import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MuiAlert from '@material-ui/lab/Alert';
import { Dialog } from 'primereact/dialog';
import { ToastContainer, toast } from 'react-toastify';
import { productMasterActions } from '../../actions';
import Switch from '@material-ui/core/Switch';
import { TabView, TabPanel } from 'primereact/tabview';
import Moment from 'react-moment';
import tableRequest from '../../models/tableRequest';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class productMaster extends Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        this.photoBodyTemplate = this.photoBodyTemplate.bind(this);
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.setImagePath = this.setImagePath.bind(this);
        this.setGenuineAudioPath = this.setGenuineAudioPath.bind(this);
        this.setAlreadyAudioPath = this.setAlreadyAudioPath.bind(this);
        this.CreatedDtTemplate = this.CreatedDtTemplate.bind(this);
        this.UpdatedDtTemplate = this.UpdatedDtTemplate.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
        this.onHide = this.onHide.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.state = {
            loading: false,
            confirm: false,
            // productSpecItem: [{ key: "", value: "" }],
            activeIndex: 1,
            buttonLabel: 'Submit',
            switchAction: false,
            displayConfirmation: false,
            formHeading: 'Add Product',
            displayModal: false,
            // productCode: '',
            productName: '',
            productDescription: '',
            promotionalMessage: '',
            permissibleScan: '',
            genuineMessage: '',
            shareMessage: '',
            shareurl: '',
            alreadyScannedMsg: '',
            warranty: '',
            productImage: '',
            genuineAudio: '',
            alreadyScannedAudio: '',
            isActive: true,
            productSpecification: [{ key: "", value: "" }],
            createdDate: null,
            updatedDate: null,
            formError: {
                // productCode: '',
                productName: '',
                // productDescription: '',
                // promotionalMessage:'',
                // permissibleScan: '',
                genuineMessage: '',
                // shareMessage:'',
                alreadyScannedMsg: '',
                // warranty:'',
                // shareurl:'',
                productImage: '',
                genuineAudio: '',
                alreadyScannedAudio: '',
            }
        };
    }
    componentDidMount() {
        this.props.fetchProducts(this.tblResponse);
    }

    switchToggleConfirm = (e) => {
        this.setState({ confirm: true, displayConfirmation: false })
        const formData = {
            productId: this.state.productId
        }
        this.props.inActiveRow(this.state.productId);
    }

    // handle input change
    handleInputChange = (e, index) => {
        ;
        const { name, value } = e.target;
        const list = [...this.state.productSpecification];
        list[index][name] = value;
        this.setState({ productSpecification: list });
    };
    // handle click event of the Remove button
    handleRemoveClick = index => {
        const list = [...this.state.productSpecification];
        list.splice(index, 1);
        this.setState({ productSpecifiation: list });
    };

    // handle click event of the Add button
    handleAddClick = () => {
        
        this.setState({ productSpecification: [...this.state.productSpecification, { key: "", value: "" }] });
    }
    getCurrentDateMMDDYYY() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }
    UpdatedDtTemplate(rowData) {
        if (rowData.UpdatedDt !== null) {
            return (
                <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>
            )
        }
    }

    CreatedDtTemplate(rowData) {
        return (
            <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>
        )
    }
    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.formError;
        switch (name) {
            case 'isActive':
                value = event.target.checked;
                break;
            default:
                break;
        }
        this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }
    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;
        switch (name) {
            // case 'productCode':
            //     if (!value || value.length < 1) {
            //         IsValid = false;
            //         errors.productCode = "Please enter your product code.";
            //     } else errors.productCode = ''
            //     break;
            case 'productName':
                if (!value || value.length < 1) {
                    IsValid = false;
                    errors.productName = "Please enter your product name.";
                } else errors.productName = ''
                break;
            // case 'productDescription':
            //     if (!value || value.length < 1) {
            //         IsValid = false;
            //         errors.productDescription = "Please enter product description.";
            //     } else errors.productDescription = ''
            //     break;
            // case 'permissibleScan':
            //     if (!value || value.length < 1) {
            //         IsValid = false;
            //         errors.permissibleScan = "Please enter permissible scan";
            //     }
            //     else errors.permissibleScan = ''
            //     break;
            case 'genuineMessage':
                if (!value || value < 1) {
                    IsValid = false;
                    errors.genuineMessage = "Please enter genuine message.";
                } else errors.genuineMessage = ''
                break;
            // case 'shareMessage':
            //     if (!value || value < 1) {
            //         IsValid = false;
            //         errors.shareMessage = "Please enter genuine message.";
            //     } else errors.shareMessage = ''
            //     break;
            // case 'shareurl':
            //     if (!value || value < 1) {
            //         IsValid = false;
            //         errors.shareurl = "Please enter Share URL.";
            //     } else errors.shareurl = ''
            //     break;
            case 'alreadyScannedMsg':
                if (!value || value.toString().length < 1) {
                    IsValid = false;
                    errors.alreadyScannedMsg = "Please enter already scanne message.";
                } else errors.alreadyScannedMsg = ''
                break;
            case 'warranty':
                // if (value.length < 1) {
                //     IsValid = false;
                //     errors.warranty = "Please enter warranty";
                // }
                // else 
                if (value.length >= 1) {
                    if (!value.match(/^([1-9]|1[012])$/)) {
                        IsValid = false;
                        errors.warranty = "Please enter valid warranty.";
                    } else errors.warranty = ''
                } else errors.warranty = ''
                break;
            case 'productImage':
                if (!value || value.toString().length < 1) {
                    IsValid = false;
                    errors.productImage = "Please upload product image.";
                } else errors.productImage = ''
                break;
            case 'genuineAudio':
                if (!value || value.length < 1) {
                    IsValid = false;
                    errors.genuineAudio = "Please upload genuine audio.";
                } else errors.genuineAudio = ''
                break;
            // case 'alreadyScannedAudio':
            //     if (!value || value.length < 1) {
            //         IsValid = false;
            //         errors.alreadyScannedAudio = "Please upload already scanne audio.";
            //     } else errors.alreadyScannedAudio = ''
            //     break;

            default:
                break;
        }

        return IsValid;
    }
    resetForm() {
        this.setState({
            productId: 0,
            // productCode: '',
            productDescription: '',
            permissibleScan: '',
            genuineMessage: '',
            alreadyScannedMsg: '',
            warranty: '',
            shareMessage: '',
            shareurl: '',
            productImage: '',
            genuineAudio: '',
            alreadyScannedAudio: '',
            isActive: true,
            productSpecification: [],
            formError: {
                // productCode: '',
                productName: '',
                productDescription: '',
                permissibleScan: '',
                genuineMessage: '',
                alreadyScannedMsg: '',
                warranty: '',
                shareMessage: '',
                shareurl: '',
                productImage: '',
                genuineAudio: '',
                alreadyScannedAudio: '',
            }
        });

        let errors = this.state.formError;
        Object.entries(errors).map(([key]) => {
            errors[key] = '';
            return errors[key];
        });
    }
    onHide() {
        this.resetForm();
        this.props.onHideShow(false);
    }

    openDialog() {
        this.props.onHideShow(true);
        this.resetForm();
        this.setState({ buttonLabel: 'Submit', formHeading: 'Add Product'})
    }

    submitForm(event) {
        event.preventDefault();
        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
            return isfrmvalid;
        });

        ;
        if (isfrmvalid) {
            const formData = {
                ProductId: this.state.productId,
                // ProductCode: this.state.productCode,
                ProductName: this.state.productName,
                ProductDescription: this.state.productDescription,
                PermissibleScan: this.state.permissibleScan == '' ? 0 : this.state.permissibleScan,
                GenuineMessage: this.state.genuineMessage,
                AlreadyScannedMsg: this.state.alreadyScannedMsg,
                warranty: this.state.warranty,
                shareMessage: this.state.shareMessage,
                ShareURL: this.state.shareurl,
                ProductImage: this.state.productImage,
                GenuineAudio: this.state.genuineAudio,
                AlreadyScannedAudio: this.state.alreadyScannedAudio,
                ProductSpecification: JSON.stringify(this.state.productSpecification),
                IsActive: this.state.isActive,
                CreatedDate: this.state.ProductId > 0 ? this.state.CreatedDate : this.getCurrentDateMMDDYYY(),
                UpdatedDate: this.state.ProductId > 0 ? this.getCurrentDateMMDDYYY() : null
            }

            if (this.state.productId == 0) {
                this.props.createProduct(formData);
            }
            else {
                this.props.updateProduct(formData);
            }

            if (!this.props.showDialog) {
                this.resetForm();
            }
        }
    }
    resetForm() {
        this.setState({
            productId: 0,
            // productCode: '',
            productDescription: '',
            permissibleScan: '',
            genuineMessage: '',
            alreadyScannedMsg: '',
            warranty: '',
            shareMessage: '',
            shareurl: '',
            productImage: '',
            genuineAudio: '',
            alreadyScannedAudio: '',
            isActive: true
        })
    }
    photoBodyTemplate(e) {
        if (e.ProductImage) {
            return <img src={e.ProductImage} width="50px" />
        }

    }
    statusBodyTemplate(rowData) {
        return (
            <Checkbox defaultChecked disabled checked={rowData.isActive} color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />
        )
    }
    // set Product Image Path
    setImagePath(event) {
        let errors = this.state.formError;
        errors.productImage = "";
        if (event.xhr) {
            if (event.xhr.response) {
                const response = JSON.parse(event.xhr.response);
                if (response) {
                    if (response.ResponseCode === "200") {
                        this.setState({
                            productImage: response.Source,
                        });
                    } else if (response.ResponseCode === "400") {
                        errors.productImage = "Only image format is accepted.";
                        this.setState({
                            productImage: "",
                        });
                    } else {
                        // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
                    }
                } else {
                    //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
                }
            } else {
                //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
            }
        } else {
            //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
    }

    // set Genuine Audio Path
    setGenuineAudioPath(event) {
        let errors = this.state.formError;
        errors.genuineAudio = "";
        if (event.xhr) {
            if (event.xhr.response) {
                const response = JSON.parse(event.xhr.response);
                if (response) {
                    if (response.ResponseCode === "200") {
                        this.setState({
                            genuineAudio: response.Source,
                        });
                    } else if (response.ResponseCode === "400") {
                        errors.genuineAudio = "Only audio format is accepted.";
                        this.setState({
                            genuineAudio: "",
                        });
                    } else {
                        // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
                    }
                } else {
                    //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
                }
            } else {
                //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
            }
        } else {
            //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
    }

    // set Already Audio Path
    setAlreadyAudioPath(event) {
        let errors = this.state.formError;
        errors.alreadyScannedAudio = "";
        if (event.xhr) {
            if (event.xhr.response) {
                const response = JSON.parse(event.xhr.response);
                if (response) {
                    if (response.ResponseCode === "200") {
                        this.setState({
                            alreadyScannedAudio: response.Source,
                        });
                    } else if (response.ResponseCode === "400") {
                        errors.alreadyScannedAudio = "Only audio format is accepted.";
                        this.setState({
                            alreadyScannedAudio: "",
                        });
                    } else {
                        // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
                    }
                } else {
                    //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
                }
            } else {
                //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
            }
        } else {
            //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
    }


    actionBodyTemplate(rowData) {
        const editMode = (e) => {
            this.props.onHideShow(true);
            this.setState({
                buttonLabel: 'Update',
                formHeading: 'Update Product',
                productId: rowData.ProductId,
                // productCode: rowData.ProductCode,
                productName: rowData.ProductName,
                productDescription: rowData.ProductDescription,
                permissibleScan: rowData.PermissibleScan,
                genuineMessage: rowData.GenuineMessage,
                alreadyScannedMsg: rowData.AlreadyScannedMsg,
                warranty: rowData.warranty,
                shareMessage: rowData.shareMessage,
                shareurl: rowData.shareurl,
                productImage: rowData.ProductImage,
                genuineAudio: rowData.GenuineAudio,
                alreadyScannedAudio: rowData.AlreadyScannedAudio,
                productSpecification: rowData.ProductSpecification != null ? JSON.parse(rowData.ProductSpecification) : [{ key: "", value: "" }],
                isActive: rowData.IsActive,
                createdDate: rowData.CreatedDate
            });
        }

        const switchHandleChange = (e) => {
            this.setState({
                switchAction: !this.state.switchAction,
                productId: rowData.ProductId
            })
            if (!this.state.switchAction) {
                this.setState({
                    displayConfirmation: true,
                    MachineId: rowData.MachineId
                });
            }
        }

        if (rowData.IsActive == true) {
            return (
                <>
                    <Switch
                        checked={true}
                        color="primary"
                        name={rowData.ProductId}
                        onChange={switchHandleChange}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />

                    <Button icon="pi pi-pencil" onClick={editMode} />
                </>
            )
        }
        else {
            return (
                <>
                    <Switch
                        checked={false}
                        color="primary"
                        disabled
                        name={rowData.ProductId}
                        onChange={switchHandleChange}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Button icon="pi pi-pencil" onClick={editMode} />
                </>
            )
        }
    }

    render() {
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => this.setState({ displayConfirmation: false })} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={this.switchToggleConfirm} autoFocus />
                </div>
            );
        }
        const header = (
            <div className="table-header row">
                <div className="col-md-6">
                    <h4>Product Master</h4>
                </div>
                <div className="col-md-6 text-right">
                    <span className="p-input-icon-left mr-2">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" />
                    </span>
                    <Button className="mr-2" icon="pi pi-plus" label="Add"
                        onClick={() => this.openDialog()} />
                </div>
            </div>
        );

        return (
            <div className="datatable-filter-demo">
                <ToastContainer />

                <div className="card">
                    <DataTable value={this.props.ProductData} ref={(el) => { this.dt = el; }} header={header} globalFilter={this.state.globalFilter} className="p-datatable-customers" rowHover={true} loading={this.state.loading} emptyMessage="No data found." sortMode="multiple" paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5, 10, 20, 50]}
                    >
                        {/* <Column field="ProductCode" header="Product Code" sortable></Column> */}
                        <Column field="ProductName" header="Product Name" sortable></Column>
                        <Column field="ProductDescription" header="Product Description" style={{ width: '470px' }} sortable></Column>
                        {/* <Column field="CreatedDt" body={this.CreatedDtTemplate} header="Created Date" sortable></Column>
                        <Column field="UpdatedDt" body={this.UpdatedDtTemplate} header="Updated Date" sortable></Column> */}
                        <Column field="ProductImage" header="Product Image" style={{ width: '200px' }} body={this.photoBodyTemplate}></Column>
                        {/* <Column field="Status" style={{ width: '70px' }} header="Status" body={this.statusBodyTemplate}></Column> */}
                        <Column field="Action" header="Action" style={{ width: '150px' }} body={this.actionBodyTemplate}></Column>

                    </DataTable>
                </div>
                <Dialog header={this.state.formHeading} modal dismissableMask={false} visible={this.props.showDialog} style={{ width: '60vw' }} onHide={this.onHide}>
                    <TabView>
                        <TabPanel header="Product">
                            <form onSubmit={this.submitForm}>
                                <div className="row">
                                    <div className="col-md-12 pt-2">
                                        {/* <TextField id="standard-basic" size="small" variant="outlined" name="productCode" value={this.state.productCode} onChange={this.handleChange} className="fullwidth" label="Product Code*" />
                                        {this.state.formError.productCode !==
                                            '' ?
                                            <div className='error'>{this.state.formError.productCode}</div> : ''} */}
                                        <TextField id="standard-basic" size="small" variant="outlined" name="productName"
                                            value={this.state.productName} onChange={this.handleChange} className="fullwidth" label="Product Name*" />
                                        {this.state.formError.productName !==
                                            '' ?
                                            <div className='error'>{this.state.formError.productName}</div> : ''}
                                    </div>
                                    <div className="col-md-6 pt-3">
                                        <TextField
                                            id="filled-textarea"
                                            className="fullwidth"
                                            rows={4}
                                            label="Product Description*"
                                            name="productDescription" value={this.state.productDescription}
                                            placeholder="Product Description*"
                                            onChange={this.handleChange}
                                            multiline
                                            variant="outlined"
                                        />
                                        {this.state.formError.productDescription !== '' ?
                                            <div className='error'>{this.state.formError.productDescription}</div> : ''}
                                    </div>
                                    <div className="col-md-6 pt-3">
                                        <TextField
                                            id="filled-textarea"
                                            className="fullwidth"
                                            rows={4}
                                            label="Promotional Message"
                                            name="promotionalMessage" value={this.state.promotionalMessage}
                                            placeholder="Promotional Message"
                                            onChange={this.handleChange}
                                            multiline
                                            variant="outlined"
                                        />

                                        {this.state.formError.productDescription !== '' ?
                                            <div className='error'>{this.state.formError.productDescription}</div> : ''}
                                    </div>
                                    <div className="col-md-6 pt-3">
                                        <TextField
                                            id="date"
                                            label="Promotion Start Date"
                                            type="date"
                                            defaultValue="2017-05-24"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6 pt-3">
                                        <TextField
                                            id="date"
                                            label="Promotion End Date"
                                            type="date"
                                            defaultValue="2017-05-24"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4 pt-3">
                                        <TextField id="standard-basic" size="small" onChange={this.handleChange} value={this.state.permissibleScan}
                                            name="permissibleScan" variant="outlined" className="fullwidth" label="Permissible Scan*" />
                                        {this.state.formError.permissibleScan !== '' ?
                                            <div className='error'>{this.state.formError.permissibleScan}</div> : ''}
                                    </div>

                                    <div className="col-md-4 pt-3">
                                        <TextField id="standard-basic" size="small" onChange={this.handleChange} value={this.state.genuineMessage}
                                            name="genuineMessage" variant="outlined" className="fullwidth" label="Genuine Message*" />
                                        {this.state.formError.genuineMessage !== '' ?
                                            <div className='error'>{this.state.formError.genuineMessage}</div> : ''}
                                    </div>

                                    <div className="col-md-4 pt-3">
                                        <TextField id="revision" size="small" onChange={this.handleChange} value={this.state.alreadyScannedMsg}
                                            name="alreadyScannedMsg" variant="outlined" className="fullwidth" label="Already Scanned Msg*" />
                                        {this.state.formError.alreadyScannedMsg !== '' ?
                                            <div className='error'>{this.state.formError.alreadyScannedMsg}</div> : ''}
                                    </div>
                                    <div className="col-md-4 pt-3">
                                        <TextField id="standard-basic" size="small" onChange={this.handleChange} value={this.state.shareurl}
                                            name="shareurl" variant="outlined" className="fullwidth" label="Share Url" />
                                        {this.state.formError.shareurl !== '' ?
                                            <div className='error'>{this.state.formError.shareurl}</div> : ''}
                                    </div>

                                    <div className="col-md-4 pt-3">
                                        <TextField id="standard-basic" size="small" onChange={this.handleChange} value={this.state.shareMessage}
                                            name="shareMessage" variant="outlined" className="fullwidth" label="Share Msg" />
                                        {this.state.formError.shareMessage !== '' ?
                                            <div className='error'>{this.state.formError.shareMessage}</div> : ''}
                                    </div>

                                    <div className="col-md-4 pt-3">
                                        <TextField id="revision" size="small" onChange={this.handleChange} value={this.state.warranty}
                                            name="warranty" variant="outlined" className="fullwidth" label="Warranty(In Month)" />
                                        {this.state.formError.warranty !== '' ?
                                            <div className='error'>{this.state.formError.warranty}</div> : ''}
                                    </div>

                                    <div className="col-md-4 pt-3">
                                        <div className="mb-2">Upload Product Image*</div>

                                        <div className="mb-2">
                                            <FileUpload
                                                name="productImage"
                                                mode="basic"
                                                url={process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"}
                                                auto={true}
                                                onUpload={this.setImagePath}
                                                onError={this.onErrorImage}
                                                accept="image/*"
                                            />

                                            {this.state.productImage ? (
                                                <div className="p-fileupload-content mt-3 p-1" style={{ display: 'table', border: '1px solid #ccc' }}>
                                                    <div>
                                                        <img
                                                            alt="pro.jfif"
                                                            role="presentation"
                                                            src={this.state.productImage}
                                                            width="100"
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}

                                            {this.state.formError.productImage !== null ? (
                                                <div className="error">
                                                    {this.state.formError.productImage}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4 pt-3">
                                        <div className="mb-2">Genuine Audio File*</div>

                                        <div className="mb-2">
                                            <FileUpload
                                                name="genuineAudio"
                                                mode="basic"
                                                url={process.env.REACT_APP_API_URL + "UploadFile/AudioUpload"}
                                                auto={true}
                                                onUpload={this.setGenuineAudioPath}
                                                onError={this.onErrorImage}
                                                accept="audio/*"
                                            />

                                            {this.state.genuineAudio ? (
                                                <div className="p-fileupload-content mt-3 p-1" style={{ display: 'table', border: '1px solid #ccc' }}>
                                                    <div>
                                                        <audio controls ref="genuineAudio">
                                                            <source src={this.state.genuineAudio} />
                                                        </audio>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {this.state.formError.genuineAudio !== null ? (
                                                <div className="error">
                                                    {this.state.formError.genuineAudio}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4 pt-3">
                                        <div className="mb-2">Already Scanned Audio File*</div>

                                        <div className="mb-2">
                                            <FileUpload
                                                name="alreadyScannedAudio"
                                                mode="basic"
                                                url={process.env.REACT_APP_API_URL + "UploadFile/AudioUpload"}
                                                auto={true}
                                                onUpload={this.setAlreadyAudioPath}
                                                onError={this.onErrorImage}
                                                accept="audio/*"
                                            />

                                            {this.state.alreadyScannedAudio ? (
                                                <div className="p-fileupload-content mt-3 p-1" style={{ display: 'table', border: '1px solid #ccc' }}>
                                                    <div>
                                                        <audio controls ref="alreadyScanneAudio">
                                                            <source src={this.state.alreadyScannedAudio} />
                                                        </audio>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {this.state.formError.alreadyScannedAudio !== null ? (
                                                <div className="error">
                                                    {this.state.formError.alreadyScannedAudio}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-12 pt-3">
                                        <Checkbox checked={this.state.isActive} onClick={this.handleChange} color="primary" name="isActive" inputProps={{ 'aria-label': 'secondary checkbox' }} />
                                        <label>Is Active</label>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <Button label={this.state.buttonLabel} icon="pi pi-check" />
                                    </div>
                                    <div className="col-md-12">
                                        Note: Fields marked as (*) are required
                                    </div>
                                </div>
                            </form>

                        </TabPanel>
                        <TabPanel header="Product Specification">
                            <div className="col-md-12 text-right">
                                <button className="btn btn-primary" onClick={this.handleAddClick}><i className="fa fa-plus pr-2"></i>Add</button>
                            </div>
                            {this.state.productSpecification.map((x, i) => {
                                return (
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Product Specification:</label>
                                                <input
                                                    name="key"
                                                    className="form-control"
                                                    placeholder="Enter Product Specification"
                                                    value={x.key}
                                                    onChange={e => this.handleInputChange(e, i)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Value:</label>
                                                <input
                                                    className="form-control"
                                                    name="value"
                                                    placeholder="Enter Value"
                                                    value={x.value}
                                                    onChange={e => this.handleInputChange(e, i)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2 pt-4">
                                            {this.state.productSpecification.length !== 1 &&
                                                <Button icon="pi pi-times" onClick={() => this.handleRemoveClick(i)} className="p-button-rounded p-button-danger p-button-text" />
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="col-md-12 text-center">
                                <button onClick={(e) => this.submitForm(e)} className="btn btn-primary">Save</button>
                            </div>
                        </TabPanel>
                    </TabView>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={renderFooter('displayConfirmation')} onHide={() => this.setState({ displayConfirmation: false })}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3 confirm-icon" style={{ fontSize: '2rem' }} />
                        <span>Are you sure you want to InActive?</span>
                    </div>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ProductData: state.product.items,
        showDialog: state.role.showDialog
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProducts: (obj) => {
            dispatch(productMasterActions.getAll(obj))
        },
        createProduct: product => dispatch(productMasterActions.createProduct(product)),
        updateProduct: product => dispatch(productMasterActions.updateProduct(product)),
        inActiveRow: product => dispatch(productMasterActions.inActiveRow(product)),
        onHideShow: isbool => dispatch(productMasterActions.onHideShow(isbool))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(productMaster)
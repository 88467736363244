export const productAuthenticationConstants = {
    CREATE_REQUEST: 'PRODUCTAUTHENTICATIONS_CREATE_REQUEST',
    CREATE_SUCCESS: 'PRODUCTAUTHENTICATIONS_CREATE_SUCCESS',
    CREATE_FAILURE: 'PRODUCTAUTHENTICATIONS_CREATE_FAILURE',

    UPDATE_REQUEST: 'PRODUCTAUTHENTICATIONS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PRODUCTAUTHENTICATIONS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PRODUCTAUTHENTICATIONS_UPDATE_FAILURE',

    GETALL_REQUEST: 'PRODUCTAUTHENTICATIONS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PRODUCTAUTHENTICATIONS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PRODUCTAUTHENTICATIONS_GETALL_FAILURE',

    DELETE_REQUEST: 'PRODUCTAUTHENTICATIONS_DELETE_REQUEST',
    DELETE_SUCCESS: 'PRODUCTAUTHENTICATIONS_DELETE_SUCCESS',
    DELETE_FAILURE: 'PRODUCTAUTHENTICATIONS_DELETE_FAILURE'    
};
import { authHeader, handleResponse } from '../helpers';
export const NumberTrackingService = {
    fetchNumberTrackingData

};

function fetchNumberTrackingData(data) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}NumberTrackingRpt/GetNumTrackReport`, requestOptions)
        .then(handleResponse);
}
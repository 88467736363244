import React, { Component } from "react";
import { Chart } from "primereact/chart";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { dashboardActions, issueNoActions, mappingActions } from "../actions";
import tableRequest from "../models/tableRequest.js";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.state = {
      globalFilter: null,
    };
    this.production = [
      {
        code: "TCDP253U5(3P)",
        name: "Holographic",
        mfgDate: "20-2-2019",
        expDate: "29-4-2020",
        mrp: 200,
        photo: "/static/media/logo.d18e70fa.png",
      },
      {
        code: "TCDP253U5(3P)",
        name: "Holographic",
        mfgDate: "20-2-2019",
        expDate: "29-4-2020",
        mrp: 123,
        photo: "null",
      },
      {
        code: "TCDP253U5(3P)",
        name: "Holographic",
        mfgDate: "20-2-2019",
        expDate: "29-4-2020",
        mrp: 866,
        photo: "null",
      },
      {
        code: "TCDP253U5(3P)",
        name: "Holographic",
        mfgDate: "20-2-2019",
        expDate: "29-4-2020",
        mrp: 343,
        photo: "null",
      },
      {
        code: "TCDP253U5(3P)",
        name: "Holographic",
        mfgDate: "20-2-2019",
        expDate: "29-4-2020",
        mrp: 343,
        photo: "null",
      },
      {
        code: "TCDP253U5(3P)",
        name: "Holographic",
        mfgDate: "20-2-2019",
        expDate: "29-4-2020",
        mrp: 343,
        photo: "null",
      },
      {
        code: "TCDP253U5(3P)",
        name: "Holographic",
        mfgDate: "20-2-2019",
        expDate: "29-4-2020",
        mrp: 343,
        photo: "null",
      },
    ];
    this.basicData = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "Pending",
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: "#42A5F5",
        },
        {
          label: "Active",
          data: [28, 48, 40, 19, 86, 27, 90],
          fill: false,
          borderColor: "#FFA726",
        },
      ],
    };
  }

  componentDidMount() {
    this.props.fetchApi(this.tblResponse);
    this.props.fetchMappingApi(this.tblResponse);
  }

  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }
  render() {
    const chartdata = {
      labels: this.props.Months,
      datasets: [
        {
          label: "Issue",
          data: this.props.MappingChart,
          fill: false,
          borderColor: "#4bc0c0",
        },
        {
          label: "Damage",
          data: this.props.DeMappingChart,
          fill: false,
          borderColor: "#9bc0c9",
        },
      ],
    };

    return (
      <div className="p-4">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <div className="cards first-box">
                  <h3>
                    <i className="fa fa-cube"></i>Number Issue
                  </h3>
                  <div className="row">
                    <div className="col-md-6  text-center">
                      <div className="overview-number">
                        {this.props.TotalIssue ? this.props.TotalIssue : 0}
                      </div>
                      {/* <div className="overview-text">RM</div> */}
                    </div>
                    {/* <div className="col-md-6 text-center">
                   <div className="overview-number">120</div>
               <div className="overview-text">RM</div>               
                   </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="cards fourth-box">
                  <h3>
                    <i className="fa fa-truck"></i>Damage
                  </h3>
                  <div className="row">
                    <div className="col-md-6  text-center">
                      <div className="overview-number">
                        {this.props.TotalDamage ? this.props.TotalDamage : 0}
                      </div>
                      {/* <div className="overview-text">Active</div> */}
                    </div>
                    {/* <div className="col-md-6 text-center">
                   <div className="overview-number">142</div>
               <div className="overview-text">Pending</div>
               
                   </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="cards">
                  {/* <h2>Dispatch</h2> */}
                  <Chart type="line" data={chartdata} />
                  {/* <Chart type="line" data={this.basicData} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="cards col-md-12">
                <h2>Number Issued</h2>
                <DataTable
                  value={this.props.IssueData}
                  ref={(el) => {
                    this.dt = el;
                  }}
                  className="p-datatable-customers"
                  rowHover={true}
                  loading={this.state.loading}
                  emptyMessage="No customers found."
                  sortMode="multiple"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  rows={5}
                  rowsPerPageOptions={[5, 10, 20, 50]}
                >
                  <Column field="FromNo" header="From No" sortable></Column>
                  <Column field="ToNo" header="To No" sortable></Column>
                  <Column field="IssueQty" header="Qty" sortable></Column>
                  <Column
                    field="CreatedDate"
                    header="Issue Date"
                    sortable
                  ></Column>
                </DataTable>
              </div>
            </div>
          </div>
          {/*   <div className="col-md-3">
                        <div className="cards second-box">
                            <h3><i className="fa fa-cubes"></i>Mapping</h3>
                            <div className="row">
                                <div className="col-md-6  text-center">
                                    <div className="overview-number">
                                        {this.props.TotalMapping ? this.props.TotalMapping : 0}
                                    </div>
                                    {/* <div className="overview-text">FG</div> 
                                </div>
                                {/* <div className="col-md-6 text-center">
                   <div className="overview-number">120</div>
               <div className="overview-text">RM</div>
               
                   </div>  
                            </div>

                        </div>
                    </div>
                  <div className="col-md-3">
                         <div className="cards third-box">
                            <h3><i className="fa fa-cubes"></i>DeMapping</h3>
                            <div className="row">
                                <div className="col-md-6 vr line text-center">
                                    <div className="overview-number">
                                        {this.props.TotalDeMapping ? this.props.TotalDeMapping : 0}
                                    </div> 
                                    <div className="overview-text">RM</div> 
                                </div>
                                 <div className="col-md-6 text-center">
                                    <div className="overview-number">420</div>
                                <div className="overview-text">Registered</div>
                                    </div> 
                            </div>

                        </div> 
                    </div>*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    DashboardList: state.dashboard?.items?.DashboardListData,
    TotalMapping: state.dashboard?.items?.DashboardCount?.TotalMapping,
    TotalDeMapping: state.dashboard?.items?.DashboardCount?.TotalDeMapping,
    TotalDamage: state.dashboard?.items?.DashboardCount?.TotalDamage,
    TotalIssue: state.dashboard?.items?.DashboardCount?.TotalIssue,
    DeMappingChart: state.dashboard?.items?.DeMappingList,
    MappingChart: state.dashboard?.items?.MappingList,
    // FGChart: state.dashboard?.items?.FGList,
    Months: state.dashboard?.items?.MonthName,
    MappingData: state.mapping.items,
    IssueData: state.issueNo.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(dashboardActions.fetchData(obj));
    },
    fetchMappingApi: (obj) => {
      dispatch(issueNoActions.getAll(obj));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import { authHeader, handleResponse } from '../helpers';
export const menuService = {
    fetchMenuData
};
 
function fetchMenuData(roleId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
 
    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/GetUserMenu/${roleId}`, requestOptions)
        .then(handleResponse);
}
import React, { Component } from 'react'
import Avtar from './../assets/images/avatar.jpg';
import TextField from '@material-ui/core/TextField';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { changePasswordActions,alertActions } from '../actions';



class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: '',
            Email:'',
            MobileNo:'',
            OldPassword: '',
            NewPassword: '',
            UserId: 0,

            formError: {
                OldPassword: null,
                NewPassword: null
            }
        }
        this.handleChange=this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
      }
    
// submit form and create or update user
submitForm(event) {
    
    event.preventDefault();

    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
        const val = this.state[key]
        let valid = this.validateForm(val, key);
        if (!valid) {
            this.setState({ checkerror: isfrmvalid });
            isfrmvalid = false;
        }

        return isfrmvalid;
    });

    if (isfrmvalid) {
        let userCollector = JSON.parse(sessionStorage.getItem("UserData"));

        const formData = {
            // UserName: this.state.UserName,
            OldPassword: this.state.OldPassword,
            NewPassword: this.state.NewPassword,
            UserId: userCollector
        }

        this.props.changePassword(formData);
    }
}

//to validate form
validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
        case 'OldPassword':
            if (value.length < 1) {
                IsValid = false;
                errors.OldPassword = "Please enter old password.";
            } else if (value.length >= 1 && this.state.NewPassword.length >= 1) {
                errors.OldPassword = '';
                errors.NewPassword = '';
                if (value === this.state.NewPassword) {
                    IsValid = false;
                    errors.OldPassword = "Old password and new password should not be same.";
                }
            } else errors.OldPassword = ''
            break;

        case 'NewPassword':
            if (value.length < 1) {
                IsValid = false;
                errors.NewPassword = "Please enter new password.";
            } else if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{4,10}$/)) {
                IsValid = false;
                errors.NewPassword = "Password should be minimum 4 characters long, 1 uppercase & 1 lowercase character with 1 number.";
            } else if (value.length >= 1 && this.state.OldPassword.length >= 1) {
                errors.OldPassword = '';
                errors.NewPassword = '';
                if (value === this.state.OldPassword) {
                    IsValid = false;
                    errors.NewPassword = "New password and old password should not be same.";
                }
            } else errors.NewPassword = ''
            break;
        default:
            break;
    }

    return IsValid;
}
// call every change of form and validation part also
handleChange(event) {

    const { name, value } = event.target;
    let errors = this.state.formError;

    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
}


    componentDidMount()
    {
        
        let userNameCollector = (sessionStorage.getItem("UserNameData"));
        let userEmailCollector = (sessionStorage.getItem("UserEmailData"));
        let userMobileCollector = (sessionStorage.getItem("UserMobileData"));

        this.setState({ UserName: userNameCollector,Email:userEmailCollector,MobileNo:userMobileCollector });

    }
    render() {
        return (
            <div className="m-5">
                <div className="row">
                <div>
                <h3>Change Password</h3>
                </div>
                </div>

                <div className="row">

                    {/* <div className="col-md-2">
                       <img src={Avtar} width="200" style={{border:'1px solid #ccc', padding:'4px'}} height="200" />
                    </div> */}
                    <div className="col-md-10">
                        <div className="row">
                        <form onSubmit={this.submitForm}>
                   
                    <div className="row profile-frm">
                        <div className="col-md-3">Name</div>
                        <div className="col-md-9"><input type="text" value={this.state.UserName} disabled /></div>
                        <div className="col-md-3">Email</div>
                        <div className="col-md-9"><input type="text" value={this.state.Email} disabled /></div>
                        <div className="col-md-3">MobileNo</div>
                        <div className="col-md-9"><input type="text" value={this.state.MobileNo} disabled /></div>
                        {/* <div className="col-md-3">Role</div>
                    <div className="col-md-9"><input type="text" value="User" disabled /></div>
                    <div className="col-md-3">Created Date</div>
                    <div className="col-md-9"><input type="text" value="01-02-2020" disabled /></div> */}
                        <div className="col-md-3">Old Password</div>
                        <div className="col-md-9">
                            <input type="password" name="OldPassword" onChange={this.handleChange} value={this.state.OldPassword} />
                            {this.state.formError.OldPassword !== '' ?
                                <div className='error'>{this.state.formError.OldPassword}</div> : ''}
                        </div>
                        <div className="col-md-3">New Password</div>
                        <div className="col-md-9">
                            <input type="password" name="NewPassword" onChange={this.handleChange} value={this.state.NewPassword} />
                            {this.state.formError.NewPassword !== '' ?
                                <div className='error'>{this.state.formError.NewPassword}</div> : ''}
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-9">
                            <button className="btn btn-primary">Update</button>
                        </div>
                    </div>
                </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        UserName:state.changePassword.items?.filter(x=>x.UserName!==null)[0]?.UserName,
        Email:state.changePassword.items?.filter(x=>x.Email!==null)[0]?.Email,
        MobileNo:state.changePassword.items?.filter(x=>x.MobileNo!==null)[0].MobileNo
     }
  }  
  
  const mapDispatchToProps = (dispatch) => {
    return {  
       fetchUserApi:() =>{
           dispatch(changePasswordActions.fetchUserData())
       },
       changePassword: changePassword => dispatch(changePasswordActions.changePassword(changePassword)) 
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Profile)
  
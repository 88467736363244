import { authHeader, handleResponse } from '../helpers';
export const roleService = {
    createRole,
    updateRole,
    fetchRoleData,
    inActiveRow,
    fetchRoleDLL,
    fetchPageData,
    updateRolePermission
};

function createRole(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}RoleMaster/CreateRole`, requestOptions)
        .then(handleResponse);
}

function updateRole(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}RoleMaster/UpdateRole`, requestOptions)
        .then(handleResponse);
}

function fetchRoleData() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}RoleMaster/GetRoles`, requestOptions)
        .then(handleResponse);
}

function inActiveRow(roleId) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}RoleMaster/InActiveRow/${roleId}`, requestOptions)
        .then(handleResponse);
}

function fetchRoleDLL() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}RoleMaster/GetRoleDLL`, requestOptions)
        .then(handleResponse);
}

function fetchPageData(roleId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}RoleMaster/GetPages/${roleId}`, requestOptions)
        .then(handleResponse);
}

function updateRolePermission(data) { 

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}RoleMaster/UpdatePagePermission`, requestOptions)
    .then(handleResponse);
}

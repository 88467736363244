export const mappingRptConstants = {
    GETALL_REQUEST: 'MAPPING_GETALL_REQUEST',
    GETALL_SUCCESS: 'MAPPING_GETALL_SUCCESS',
    GETALL_FAILURE: 'MAPPING_GETALL_FAILURE',
    GET_ALL_Out_SUCCESS: 'MAPPING_GETALL_Out_SUCCESS',

    GETEXPORT_REQUEST: 'MAPPING_GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'MAPPING_GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'MAPPING_GETEXPORT_FAILURE',

    LODING_REQUEST: 'LODING_REQUEST'
}
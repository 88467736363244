import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { roleMasterActions, alertActions } from "../../actions";

class roleMaster extends React.Component {
    constructor(props) {
        super();
        //this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.editPermission = this.editPermission.bind(this);
        this.createdDateTemplate = this.createdDateTemplate.bind(this);
        this.updatedDateTemplate = this.updatedDateTemplate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.onHide = this.onHide.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.checkboxIsView = this.checkboxIsView.bind(this);
        this.checkboxIsAdd = this.checkboxIsAdd.bind(this);
        this.checkboxIsEdit = this.checkboxIsEdit.bind(this);
        this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
        this.onchangeCheckbox = this.onchangeCheckbox.bind(this);

        this.state = {
            loading: false,
            switchAction: false,
            node: [],
            confirm: false,
            formHeading: 'Add Role',
            buttonLabel: 'Submit',
            displayConfirmation: false,
            switchAction: false,
            RoleId: 0,
            RoleName: '',
            RoleDesc: '',
            Address: '',
            IsActive: true,
            CreatedDate: null,
            allViewCheck: false,
            allAddCheck: false,
            allEditCheck: false,
            formError: {
                RoleName: '',
                RoleDesc: ''
            }
        };
    }

    componentDidMount() {
        this.props.fetchApi();
    }

    switchToggleConfirm = (e) => {
        this.setState({ confirm: true, displayConfirmation: false })
        const formData = {
            RoleId: this.state.RoleId
        }
        this.props.inActiveRow(this.state.RoleId);
    }

    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.formError;
        switch (name) {
            case 'IsActive':
                value = event.target.checked;
                break;
            default:
                break;
        }
        this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }

    updateRolePermission(event) {
        event.preventDefault();
        let permissionPage = [];
        const permissionArray = this.props.Pages;

        if (permissionArray.length > 0) {
            this.props.updateRolePermission(permissionArray);
        } else {
            this.props.warning("No page is selected")
        }
    }

    getCurrentDateMMDDYYY() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }
    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;

        switch (name) {
            case 'RoleName':
                if (value.length < 1) {
                    IsValid = false;
                    errors.RoleName = "Please enter role name.";
                } else errors.RoleName = ''
                break;
            case 'RoleDesc':
                if (value.length < 1) {
                    IsValid = false;
                    errors.RoleDesc = "Please enter role remarks.";
                } else errors.RoleDesc = ''
                break;
            default:
                break;
        }

        return IsValid;
    }

    updatedDateTemplate(rowData) {
        if (rowData.UpdatedDate !== null) {
            return (
                <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>
            )
        }

    }

    createdDateTemplate(rowData) {
        return (
            <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>
        )
    }

    submitForm(event) {

        event.preventDefault();
        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
            return isfrmvalid;
        });

        if (isfrmvalid) {
            const formData = {
                RoleId: this.state.RoleId,
                RoleName: this.state.RoleName,
                RoleDesc: this.state.RoleDesc,
                IsActive: this.state.IsActive,
                CreatedDate: this.state.RoleId > 0 ? this.state.CreatedDate : this.getCurrentDateMMDDYYY(),
                UpdatedDate: this.state.RoleId > 0 ? this.getCurrentDateMMDDYYY() : null
            }

            if (this.state.RoleId == 0) {
                this.props.createRole(formData);
            }
            else {
                this.props.updateRole(formData);
            }

            if (!this.props.showDialog) {
                this.resetForm();
            }
        }
    }

    resetForm() {
        this.setState({
            RoleId: 0,
            RoleName: '',
            RoleDesc: '',
            IsActive: true,
            formError: {
                RoleName: '',
                RoleDesc: ''
            }
        });

        let errors = this.state.formError;
        Object.entries(errors).map(([key]) => {
            errors[key] = '';
            return errors[key];
        });
    }
    checkAll(event) {
        this.props.updatePageStore(null);
        var ischecked = event.currentTarget.checked;
        var name = event.currentTarget.name;
        var pagelist = this.props.Pages;

        for (let i = 0; i < pagelist.length; i++) {
            pagelist[i][name] = ischecked;
        }
        if (name === 'IsView') {
            this.setState({ allViewCheck: ischecked });
        } else if (name === 'IsAdd') {
            this.setState({ allAddCheck: ischecked });
        } else if (name === 'IsEdit') {
            this.setState({ allEditCheck: ischecked });
        }

        this.props.updatePageStore(pagelist);
    }
    onchangeCheckbox(rowdate, event, key) {
        ;
        this.props.updatePageStore(null);
        var ischecked = event.currentTarget.checked;
        var name = event.currentTarget.id;
        var pagelist = this.props.Pages;

        for (let i = 0; i < pagelist.length; i++) {
            if (pagelist[i].PageId === parseInt(name)) {
                pagelist[i][key] = ischecked;
            }
        }

        this.props.updatePageStore(pagelist);
    }

    checkboxIsView(rowData) {
        //console.log('test');
        return (
            <Checkbox
                checked={rowData.IsView}
                onChange={(e) => this.onchangeCheckbox(rowData, e, 'IsView')}
                id={rowData.PageId}
                color="primary"
                inputProps={{ 'aria-label': 'first checkbox' }}
            />
        )
    }
    checkboxIsAdd(rowData) {
        return (
            <Checkbox
                onChange={(e) => this.onchangeCheckbox(rowData, e, 'IsAdd')}
                checked={rowData.IsAdd}
                id={rowData.PageId}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
        )
    }
    checkboxIsEdit(rowData) {
        return (
            <Checkbox
                onChange={(e) => this.onchangeCheckbox(rowData, e, 'IsEdit')}
                checked={rowData.IsEdit}
                id={rowData.PageId}
                color="primary"
                inputProps={{ 'aria-label': 'thirds checkbox' }}
            />
        )
    }
    statusBodyTemplate(rowData) {
        return (
            <Checkbox
                defaultChecked
                disabled
                checked={rowData.IsActive}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
        )
    }

    onHide() {
        this.props.onHideShow(false);
        this.resetForm();
    }
    openDialog() {
        this.props.onHideShow(true);
        this.setState({ buttonLabel: 'Submit', formHeading: 'Add Role' })
    }

    editPermission(rowData, RoleName) {
        const roleId = rowData.currentTarget.value;

        if (rowData.currentTarget.value) {
            this.setState({RoleName: RoleName})
            this.props.getPages(rowData.currentTarget.value);
        }
    }
    actionBodyTemplate(rowData) {
        const editMode = (e) => {
            this.props.onHideShow(true);
            this.setState({
                formHeading: 'Update Role',
                buttonLabel: 'Update',
                RoleId: rowData.RoleId,
                RoleName: rowData.RoleName,
                RoleDesc: rowData.RoleDesc,
                IsActive: rowData.IsActive,
                CreatedDate: rowData.CreatedDate
            })
        }
        const switchHandleChange = (e) => {

            this.setState({
                switchAction: !this.state.switchAction
            })
            if (!this.state.switchAction) {
                this.setState({
                    displayConfirmation: true,
                    RoleId: rowData.RoleId
                });
            }
        }

        if (rowData.IsActive == true) {
            return (
                <>
                    <Switch
                        checked={true}
                        color="primary"
                        name={rowData.RoleId}
                        onChange={switchHandleChange}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Button icon="pi pi-pencil" className="mr-2" onClick={editMode} />
                    <Button icon="pi pi-cog" value={rowData.RoleId} className="p-button-sm" onClick={(e)=>{this.editPermission(e, rowData.RoleName)}} />
                </>
            )
        }
        else {
            return (
                <>
                    <Switch
                        checked={false}
                        color="primary"
                        disabled
                        name={rowData.RoleId}
                        onChange={switchHandleChange}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Button icon="pi pi-pencil" className="mr-2" onClick={editMode} />
                    <Button icon="pi pi-cog" value={rowData.RoleId} className="p-button-sm" onClick={this.editPermission} />
                </>
            )
        }
    }

    render() {
        var listPages = this.props.Pages;
        if (listPages) {
            var isAllView = listPages.find(x => x.IsView == false);
            if (isAllView) {
                this.state.allViewCheck = false

            } else {
                this.state.allViewCheck = true
            }

            var isAllAdd = listPages.find(x => x.IsAdd == false);
            if (isAllAdd) {
                this.state.allAddCheck = false

            } else {
                this.state.allAddCheck = true
            }

            var isAllEdit = listPages.find(x => x.IsEdit == false);
            if (isAllEdit) {
                this.state.allEditCheck = false

            } else {
                this.state.allEditCheck = true
            }
        }

        const renderFooter = (name) => {

            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => this.setState({ displayConfirmation: false })} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={this.switchToggleConfirm} autoFocus />
                </div>
            );
        }
        const header = (
            <div className="table-header row">
                <div className="col-md-6">
                    <h4>User Role</h4>
                </div>
                <div className="col-md-6 text-right">
                    <span className="p-input-icon-left mr-2">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" />
                    </span>
                    <Button className="mr-2" icon="pi pi-plus" label="Add" onClick={() => this.openDialog()} />
                    {/* <Button type="button" icon="pi pi-external-link" label="Export" onClick={this.exportCSV}></Button> */}
                </div>

            </div>
        );
        const header2 = (
            <div className="table-header row">
                <div className="col-md-6">
                    <h4>Build Menus List</h4>
                </div>
                <div className="col-md-6 text-right">
                   {this.state.RoleName}
                </div>

            </div>
        )
        // <Dialog header="Edit Permission" modal dismissableMask={true} visible={this.state.permissionModal} style={{ width: '50vw' }} onHide={() => this.setState({ permissionModal: false })}>
        //     Test
        // </Dialog>
        const viewHeading = (<div>View <Checkbox
            checked={this.state.allViewCheck}
            onChange={(e) => { this.checkAll(e) }}
            name='IsView'
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
        /></div>)
        const addHeading = (<div>Add <Checkbox
            checked={this.state.allAddCheck}
            onChange={(e) => { this.checkAll(e) }}
            name='IsAdd'
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
        /></div>)
        const updateHeading = (<div>Update <Checkbox
            checked={this.state.allEditCheck}
            onChange={(e) => { this.checkAll(e) }}
            name='IsEdit'
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
        /></div>)
        return (
            <div className="datatable-filter-demo">
                <div className="row">
                    <div className="col-md-7">

                        <div className="card">
                            <DataTable value={this.props.RoleData} ref={(el) => { this.dt = el; }} header={header} globalFilter={this.state.globalFilter} className="p-datatable-customers" rowHover={true} loading={this.state.loading} emptyMessage="No data found." sortMode="multiple" paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5, 10, 20, 50]} >
                               
                                <Column field="RoleName" header="Role Name" sortable></Column>
                                <Column field="RoleDesc" header="Remarks" sortable></Column>
                                {/* <Column field="CreatedDate" body={this.createdDateTemplate} header="Created Date" sortable></Column>
                                <Column field="UpdatedDate" body={this.updatedDateTemplate} header="Updated Date" sortable></Column> */}
                                {/* <Column field="IsActive" style={{ width: '70px' }} header="Status" body={this.statusBodyTemplate}></Column> */}
                                <Column field="Action" header="Action" style={{ width: '300px' }} body={this.actionBodyTemplate}></Column>
                                {/* {dynamicColumns} */}
                            </DataTable>
                        </div>

                    </div>
                    <div className="col-md-5">
                        <div className="card page-list-scroll">
                            <DataTable value={this.props.Pages} ref={(el) => { this.dt = el; }} header={header2} globalFilter={this.state.globalFilter}
                                className="p-datatable-customers" scrollable scrollHeight="200px" rowHover={true} loading={this.state.loading}
                                emptyMessage="No data found." sortMode="multiple">
                                <Column field="PageName" header="Menu Name" style={{ width: '155px' }} sortable></Column>
                                <Column field="IsView" header={viewHeading} body={this.checkboxIsView}></Column>
                                <Column field="IsAdd" header={addHeading} body={this.checkboxIsAdd}></Column>
                                <Column field="IsEdit" header={updateHeading} body={this.checkboxIsEdit}></Column>
                            </DataTable>
                            <div className="col-md-12 text-center mt-2 mb-2">
                                <button type="button" onClick={(e) => { this.updateRolePermission(e) }} className="btn btn-primary">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog header={this.state.formHeading} modal dismissableMask={false} visible={this.props.showDialog} style={{ width: '50vw' }} onHide={this.onHide}>
                    <form onSubmit={this.submitForm}>
                        <div className="row">

                            <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} value={this.state.RoleName} name="RoleName" id="standard-basic" size="small" variant="outlined" className="fullwidth" label="Role Name*" />
                                {this.state.formError.RoleName !== '' ?
                                    <div className='error'>{this.state.formError.RoleName}</div> : ''}
                            </div>

                            <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} value={this.state.RoleDesc} name="RoleDesc" id="standard-basic" size="small" variant="outlined" className="fullwidth" label="Remarks*" />
                                {this.state.formError.RoleDesc !== '' ?
                                    <div className='error'>{this.state.formError.RoleDesc}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-3">
                                <Checkbox onClick={this.handleChange} checked={this.state.IsActive} color="primary" name="IsActive" inputProps={{ 'aria-label': 'secondary checkbox' }} />
                                <label>Is Active</label>
                            </div>
                            <div className="col-md-12 mt-3 text-center">
                                <Button label={this.state.buttonLabel} icon="pi pi-check" />
                            </div>
                            <div className="col-md-12">
                                Note: Fields marked as (*) are required
                            </div>
                            {/* <div checked={this.state.IsActive} name="IsActive" className="col-md-12 mt-3 text-center">
                                <Button label={this.state.buttonLabel} icon="pi pi-check" />
                            </div> */}
                        </div>
                    </form>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={renderFooter('displayConfirmation')} onHide={() => this.setState({ displayConfirmation: false })}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3 confirm-icon" style={{ fontSize: '2rem' }} />
                        <span>Are you sure you want to InActive?</span>
                    </div>
                </Dialog>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        RoleData: state.role.items,
        showDialog: state.role.showDialog,
        Pages: state.role.pages
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: () => {
            dispatch(roleMasterActions.getAll())
        },
        openPermissionDialog: data => dispatch(roleMasterActions.openDialog(data)),
        createRole: data => dispatch(roleMasterActions.createRole(data)),
        updateRole: data => dispatch(roleMasterActions.updateRole(data)),
        inActiveRow: data => dispatch(roleMasterActions.inActiveRow(data)),
        onHideShow: isbool => dispatch(roleMasterActions.onHideShow(isbool)),
        getPages: (roleId) => dispatch(roleMasterActions.getPages(roleId)),
        updatePageStore: (pagelist) => dispatch(roleMasterActions.updatePageStore(pagelist)),
        warning: message => dispatch(alertActions.warning(message)),
        updateRolePermission: data => dispatch(roleMasterActions.updateRolePermission(data)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(roleMaster)
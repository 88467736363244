import { issueNoConstants } from '../constants';

export function issueNo(state = {}, action) {
    switch (action.type) {
        case issueNoConstants.DIALOG_REQUEST:
            return {
                ...state,
                showDialog: action.isbool
            };
        case issueNoConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case issueNoConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case issueNoConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case issueNoConstants.GETALL_FROMNO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case issueNoConstants.GETALL_FROMNO_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case issueNoConstants.GETALL_FROMNO_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case issueNoConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            };
        case issueNoConstants.CREATE_SUCCESS:
            return {
                ...state,
                items: state.items == null ? [action.data] : [action.data, ...state.items]
            };
        case issueNoConstants.CREATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case issueNoConstants.UPDATE_REQUEST:
            return {
                ...state,
                items: state.items.map(item =>
                    item.IssueId == action.data.IssueId ? { ...item, updating: true } : item
                )
            };
        case issueNoConstants.UPDATE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.IssueId === action.data.IssueId ? action.data : item
                )
            };
        case issueNoConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case issueNoConstants.DELETE_REQUEST:
            return {
                ...state,
                deleting: true
            };
        case issueNoConstants.DELETE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.issueId === action.data ? {
                        CreatedBy: item.CreatedBy,
                        CreatedDt: item.CreatedDt,
                        issueId: item.issueId,
                        salesOrderNo: item.salesOrderNo,
                        companyId: item.companyId,
                        UpdatedBy: item.UpdatedBy,
                        UpdatedDt: item.UpdatedDt,
                        FrmNo: item.FrmNo,
                        ToNo: item.ToNo,
                        IssueQty: item.IssueQty
                    } : item
                )
            };
        case issueNoConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case issueNoConstants.GET_BATCH_DDL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case issueNoConstants.GET_BATCH_DDL_SUCCESS:
            return {
                ...state,
                batchddl: action.datas
            };
        case issueNoConstants.GET_BATCH_DDL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case issueNoConstants.GET_PLANT_DDL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case issueNoConstants.GET_PLANT_DDL_SUCCESS:
            return {
                ...state,
                plantddl: action.datas
            };
        case issueNoConstants.GET_PLANT_DDL_FAILURE:
            return {
                ...state,
                error: action.error
            };
            case issueNoConstants.GET_BATCHDETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case issueNoConstants.GET_BATCHDETAILS_SUCCESS:
            return {
                ...state,
                batchDetails: action.datas
            };
        case issueNoConstants.GET_BATCHDETAILS_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }
}

import { FeedbackRptConstants } from '../constants';

export function FeedbackRpt(state = {}, action) {
    switch (action.type) {
        case FeedbackRptConstants.LODING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };

            case FeedbackRptConstants.GETEXPORT_REQUEST:
                return {
                    ...state,
                    exporting: true,
                };
            case FeedbackRptConstants.GETEXPORT_SUCCESS:
                return {
                    ...state,
                    exportitems: action.datas,
                };
            case FeedbackRptConstants.GETEXPORT_FAILURE:
                return {
                    ...state,
                    error: action.error,
                };
    
        case FeedbackRptConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FeedbackRptConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case FeedbackRptConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }

}
import { QRDetailsRptConstants } from '../constants';

export function QRDetailsRpt(state = {}, action) {
    switch (action.type) {
        case QRDetailsRptConstants.LODING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };

        case QRDetailsRptConstants.GETEXPORT_REQUEST:
            return {
                ...state,
                exporting: true,
            };
        case QRDetailsRptConstants.GETEXPORT_SUCCESS:
            return {
                ...state,
                exportitems: action.datas,
            };
        case QRDetailsRptConstants.GETEXPORT_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case QRDetailsRptConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case QRDetailsRptConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case QRDetailsRptConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }

}
export const FeedbackRptConstants = {
    GETALL_REQUEST: 'FEEDBACK_GETALL_REQUEST',
    GETALL_SUCCESS: 'FEEDBACK_GETALL_SUCCESS',
    GETALL_FAILURE: 'FEEDBACK_GETALL_FAILURE',

    GETEXPORT_REQUEST: 'FEEDBACK_GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'FEEDBACK_GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'FEEDBACK_GETEXPORT_FAILURE',

    LODING_REQUEST: 'LODING_REQUEST'
}
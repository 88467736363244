import { authHeader, handleResponse } from '../helpers';
export const verificationLogServices = {
    fetchVerificationData
   
};

function fetchVerificationData(data) {
    ;
    //console.log(data);
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}VerificationLog/GetVerificationReport`, requestOptions)
        .then(handleResponse);
}
import { authHeader, handleResponse } from '../helpers';
export const mappingService = {
    createMapping,
    fetchMappingData,
    fetchToNo
};

function createMapping(data) {  

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Mapping/CreateMapping`, requestOptions)
    .then(handleResponse);
}


function fetchMappingData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}Mapping/GetMappings`, requestOptions)
    .then(handleResponse);
}
function fetchToNo() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}Mapping/GetToNumbers`,requestOptions)
    .then(handleResponse);
}






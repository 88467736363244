import { NumberTrackingService } from '../services';
import { NumberTrackingRptConstants } from '../constants';
import { alertActions } from '.';

export const NumberTrackingRptActions = {
    getAll
};

function getAll(obj) {
    return dispatch => {
        dispatch(request());
        NumberTrackingService.fetchNumberTrackingData(obj).then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }

        );
    };
    function request() { return { type: NumberTrackingRptConstants.GETALL_REQUEST } }
    function success(datas) { return { type: NumberTrackingRptConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: NumberTrackingRptConstants.GETALL_FAILURE, error } }

}
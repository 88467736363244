import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MuiAlert from '@material-ui/lab/Alert';
import { Dialog } from 'primereact/dialog';
import { ToastContainer, toast } from 'react-toastify';
import {  productConfigActions, productMasterActions } from '../../actions';
import Switch from '@material-ui/core/Switch';
import { TabView, TabPanel } from 'primereact/tabview';
import Moment from 'react-moment';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class ProductConfiguration extends Component {
    constructor(props) {
        super(props);
        this.photoBodyTemplate = this.photoBodyTemplate.bind(this);
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.CreatedDtTemplate = this.CreatedDtTemplate.bind(this);
        this.UpdatedDtTemplate = this.UpdatedDtTemplate.bind(this);
        this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
        this.onHide = this.onHide.bind(this);
        this.getProductList = this.getProductList.bind(this);
        this.singleCheck = this.singleCheck.bind(this);
        this.getConfigurationList = this.getConfigurationList.bind(this);
        this.configureField2 = this.configureField2.bind(this);
        this.configureField = this.configureField.bind(this);
        this.state = {
            loading: false,
            confirm: false,
            productSpecItem: [{ productSpec: '', value: '' }],
            ConfigurationData: [{
                ConfigureItem: 'Company Logo', Value: "CompanyLogo",
                ConfigureItem1: 'Product Image', Value1: "ProductImage"
            },
            //{ ConfigureItem: 'Product Image', Value: "ProductImage" },

            {
                ConfigureItem: 'Product Information', Value: "ProductInformation",
                ConfigureItem1: 'Product Rating', Value1: "ProductRating"
            },
            // { ConfigureItem: 'Product Rating', Value: "ProductRating" },
            {
                ConfigureItem: 'Share', Value: "Share",
                ConfigureItem1: 'Feedback', Value1: "Feedback"
            },
            // { ConfigureItem: 'Feedback', Value: "Feedback" },
            { ConfigureItem: 'Genuine Audio', Value: "GenuineAudio", ConfigureItem1: 'Already Scanned Audio', Value1: "AlreadyScannedAudio" },
            // { ConfigureItem: 'Already Scanned Audio', Value: "AlreadyScannedAudio" },
            { ConfigureItem: 'Genuine Message', Value: "GenuineMessage", ConfigureItem1: 'Already Scanned Message', Value1: "AlreadyScannedMessage" },
            // { ConfigureItem: 'Already Scanned Message', Value: "AlreadyScannedMessage" },
            {
                ConfigureItem: 'Promotional Messages', Value: "PromotionalMessages",
                ConfigureItem1: 'Similar Products', Value1: "SimilarProducts"
            },
            // { ConfigureItem: 'Similar Products', Value: "SimilarProducts" },
            { ConfigureItem: 'Similar Products Rating', Value: "SimilarProductsRating" }
            ],
            selectedProduct: '',
            activeIndex: 1,
            buttonLabel: 'Submit',
            switchAction: false,
            displayConfirmation: false,
            formHeading: 'Add Product',
            displayModal: false,

            UpdatedDt: null,
            productId: '',
            IsCheckedAll: false,
            productConfiguration: '',
            formError: {
                productId: '',
                productConfiguration: '',
            }
        };
    }

    componentDidMount() {

    }

    singleCheck() {
        ;
        let obj = {};
        const checkboxes = document.getElementsByName('childCheck');
        for (var checkbox of checkboxes) {
            if (checkbox.checked) {
                obj[checkbox.value] = true;
            } else {
                obj[checkbox.value] = false;
            }
        }

        return JSON.stringify(obj);
    }

    switchToggleConfirm = (event) => {
        this.setState({ confirm: true, displayConfirmation: false })
        //  const formData = {
        //      productId: this.state.productId
        //  }
        // this.props.inActiveRow(this.state.productId);
    }

    getProductList(event) {
        this.props.getProductDDL();
    }

    getConfigurationList(event) {
        ;
        this.setState({ productId: event.target.value })
        this.props.getconfigDDL(event.target.value);
    }

    allCheck(event) {
        ;
        this.setState({ IsCheckedAll: event.target.checked })
    }


    // handle input change
    handleInputChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...this.state.productSpecItem];
        list[index][name] = value;
        this.setState({ productSpecItem: list });


    };
    // handle click event of the Remove button
    handleRemoveClick = index => {
        const list = [...this.state.productSpecItem];
        list.splice(index, 1);
        this.setState({ productSpecItem: list });
    };

    // handle click event of the Add button
    handleAddClick = () => {
        
        this.setState({ productSpecItem: [...this.state.productSpecItem, { productSpec: "", value: "" }] });
    }
    getCurrentDateMMDDYYY() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }
    UpdatedDtTemplate(rowData) {
        if (rowData.UpdatedDt !== null) {
            return (
                <Moment format="D MMM YYYY">{rowData.UpdatedDt}</Moment>
            )
        }
    }

    CreatedDtTemplate(rowData) {
        return (
            <Moment format="D MMM YYYY">{rowData.CreatedDt}</Moment>
        )
    }
    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.formError;
        switch (name) {
            case 'isActive':
                value = event.target.checked;
                break;
            default:
                break;
        }
        this.validateForm(value, name);
        this.setState({ errors, [name]: value });

        let checked = event.target.checked;
        this.setState(prevState => {
            let { list, allChecked } = prevState;
            if (name === "checkAll") {
                allChecked = checked;
                list = list.map(item => ({ ...item, isChecked: checked }));
            } else {
                list = list.map(item =>
                    item.name === name ? { ...item, isChecked: checked } : item
                );
                allChecked = list.every(item => item.isChecked);
            }
            return { list, allChecked };
        });
    };

    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;
        switch (name) {
            case 'productId':
                if (value.length < 1) {
                    IsValid = false;
                    errors.productId = "Please select Product.";
                } else errors.productId = ''
                break;



            default:
                break;
        }

        return IsValid;
    }
    resetForm() {
        this.setState({
            productId: '',
            productConfiguration: '',
            IsCheckedAll: '',

            formError: {
                productId: '',
                productConfiguration: '',
                IsCheckedAll: ''

            }
        });

        let errors = this.state.formError;
        Object.entries(errors).map(([key]) => {
            errors[key] = '';
            return errors[key];
        });
    }


    onHide() {
        this.setState({ displayModal: false })
        //this.props.onHideShow(false);
        this.resetForm();
    }

    // onreset() {
    //     this.state.onreset(false);
    // }

    // CheckAll(chk)
    // {
    //     for (i = 0; i < chk.length; i++)
    //     chk[i].checked = true ;
    // }

    // UnCheckAll(chk)
    // {
    //     for (i = 0; i < chk.length; i++)
    //     chk[i].checked = false ;
    // }

    submitForm(event) {
        
        event.preventDefault();
        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;

            }
            return isfrmvalid;
        });


        if (isfrmvalid) {
            var confg = this.singleCheck();
            var products = [];
            //this.state.productId.forEach(element => {
            ;
            var obj = new Object();
            obj.productId = this.state.productId;
            obj.ProductConfiguration = confg;
            products.push(obj);
            //});

            var formdata = {
                IsCheckedAll: this.state.IsCheckedAll,
                ProductMasters: products,
                //ProductConfiguration: this.state.ProductConfiguration
            }
            this.props.updateproductConfiguration(formdata);
        }
        this.resetForm();

    }

    photoBodyTemplate(e) {
        return <img src={e.ProductImg} width="50px" />
    }
    statusBodyTemplate(rowData) {
        return (
            <Checkbox defaultChecked disabled checked={rowData.isActive} color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />
        )
    }

    // set Image Path
    // setImagePath(event) {
    //     let errors = this.state.formError;
    //     errors.ProductImg = "";
    //     if (event.xhr) {
    //         if (event.xhr.response) {
    //             const response = JSON.parse(event.xhr.response);
    //             if (response) {
    //                 if (response.ResponseCode === "200") {
    //                     this.setState({
    //                         productImg: response.Source,
    //                     });
    //                 } else if (response.ResponseCode === "400") {
    //                     errors.ProductImg = "Only image format is accepted.";
    //                     this.setState({
    //                         productImg: "",
    //                     });
    //                 } else {
    //                     // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
    //                 }
    //             } else {
    //                 //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    //             }
    //         } else {
    //             //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    //         }
    //     } else {
    //         //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    //     }
    // }

    changevalue(event) {

        ;
        var ischeck = event.currentTarget.checked;
        var id = event.currentTarget.id;
        setTimeout(() => {
            this.checkCheckBox(ischeck, id);
        }, 500);

    }

    checkCheckBox(ischeck, id) {
        document.getElementById(id).checked = ischeck;
    }

    configureField2(rowData) {
        ;
        return (
            <div>{rowData.ConfigureItem1}</div>
        )
    }
    configureField(rowData) {
        return (
            <div>{rowData.ConfigureItem}</div>
        )
    }

    actionBodyTemplate(rowData, i) {
        if (i.field == '1') {
            if (rowData.hasOwnProperty('Value')) {
                return (
                    <>
                        {
                            <input type="checkbox" id={rowData.Value} value={rowData.Value} color="primary" onChange={(e) => this.changevalue(e)} color="primary" name="childCheck" resetFilterOnHide="true" inputProps={{ 'aria-label': 'primary checkbox' }} />
                            // <input type="checkbox" id={rowData.Value} checked={isChecked} onChange={(e) => this.changevalue(e)} name="childCheck" value={rowData.Value} resetFilterOnHide="true" />
                        }
                    </>
                )
            }


        } else if (i.field == '2') {
            if (rowData.hasOwnProperty('Value1')) {
                return (
                    <>
                        {<input type="checkbox" id={rowData.Value1} value={rowData.Value1} color="primary" onChange={(e) => this.changevalue(e)} color="primary" name="childCheck" resetFilterOnHide="true" inputProps={{ 'aria-label': 'primary checkbox' }} />
                            // <input type="checkbox" id={rowData.Value} checked={isChecked} onChange={(e) => this.changevalue(e)} name="childCheck" value={rowData.Value} resetFilterOnHide="true" />
                        }
                    </>
                )
            }
        }


    }
    actionBodyTemplate2(rowData) {
        ;
        return (
            <>
                {
                    <Checkbox id={rowData.Value1} color="primary" onChange={(e) => this.changevalue(e)} color="primary" name="childCheck" resetFilterOnHide="true" inputProps={{ 'aria-label': 'primary checkbox' }} />
                    // <input type="checkbox" id={rowData.Value} checked={isChecked} onChange={(e) => this.changevalue(e)} name="childCheck" value={rowData.Value} resetFilterOnHide="true" />
                }

            </>
        )

    }
    // exportCSV() {
    //     this.dt.exportCSV();
    // }

    render() {
        ;
        const configur = this.props.configddl;
        const checkboxes = document.getElementsByName('childCheck');
        if (configur) {
            const obj = JSON.parse(configur);
            Object.entries(obj).map(([key, value]) => {
                ;
                if (key) {
                    for (var checkbox of checkboxes) {
                        if (key === checkbox.value) {
                            const keyval = value;
                            if (keyval) {
                                document.getElementById(key).checked = true;
                            } else {
                                checkbox.checked = false;
                            }
                        }
                    }
                } else {
                    checkbox.checked = false;
                }

            })
        } else {
            for (let checkbox of checkboxes) {
                checkbox.checked = false;
            }
        }


        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => this.setState({ displayConfirmation: false })} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={this.switchToggleConfirm} autoFocus />
                </div>
            );
        }
        const header = (
            <div className="table-header row">
                <div className="col-md-5">
                    <h4>Product Configuration</h4>
                </div>

                <div className="col-md-7 text-right">
                    <span className="p-input-icon-left mr-2 text-left">

                        <Dropdown value={this.state.productId} filter="true"
                            filterPlaceholder="Search Products" style={{ width: '250px' }}
                            emptyFilterMessage="No Result Found"
                            resetFilterOnHide="true"
                            options={this.props.productData}
                            onChange={(e) => this.getConfigurationList(e)}
                            placeholder="Select Product" />
                        {this.state.formError.productId !== '' ?
                            <div className='error'>{this.state.formError.productId}</div> : ''}

                    </span>
                    <Checkbox
                        checked={this.state.IsCheckedAll}
                        onChange={(e) => this.allCheck(e)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    {/* <input type="checkbox" value={this.state.IsCheckedAll} onChange={(e) => this.allCheck(e)}></input> */}
                    <span className="pl-2">Configure for all products</span>
                </div>

            </div>
        );

        return (
            <div className="datatable-filter-demo">
                <ToastContainer />

                <div className="card">
                    <form onSubmit={this.submitForm}>


                        <DataTable value={this.state.ConfigurationData} ref={(el) => { this.dt = el; }}
                            header={header} globalFilter={this.state.globalFilter} className="p-datatable-customers"
                            rowHover={true} loading={this.state.loading} emptyMessage="No data found." singleCheck={false} sortMode="multiple"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">


                            <Column body={this.configureField} header="Configuration" sortable></Column>

                            <Column header="Select" field="1"
                                body={this.actionBodyTemplate} >

                            </Column>
                            <Column body={this.configureField2} header="Configuration" sortable></Column>

                            <Column header="Select" field="2"
                                body={this.actionBodyTemplate} >

                            </Column>
                        </DataTable>

                    </form>

                    <div className="col-md-12 m-3 text-center">
                        <button onClick={(e) => this.submitForm(e)} className="btn btn-primary">Save</button>
                    </div>

                    <Dialog header={this.state.formHeading} modal dismissableMask={false} visible={this.props.showDialog} style={{ width: '50vw' }} onHide={this.onHide} ></Dialog>

                    <Dialog header="Confirmation" visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={renderFooter('displayConfirmation')} onHide={() => this.setState({ displayConfirmation: false })}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3 confirm-icon" style={{ fontSize: '2rem' }} />
                        </div>
                    </Dialog>

                    {/* <Dialog header={this.state.formHeading} visible={this.props.showDialog} style={{ width: '50vw' }} onreset={() => this.setState({ Checked: false })}></Dialog> */}


                </div>



            </div>

        )
    }
}
const mapStateToProps = (state) => {
    return {
        productData: state.product.prdctddl,
        configddl: state.productConfiguration.configddl
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getProductDDL: () => {
            ;
            dispatch(productMasterActions.getPrdctDDL())
        },

        getconfigDDL: (productid) => {
            dispatch(productConfigActions.getconfigDDL(productid))
        },
        // createProduct: product => dispatch(productMasterActions.createProduct(product)),
        updateproductConfiguration: data => dispatch(productConfigActions.updateproductConfiguration(data)),
        // inActiveRow: product => dispatch(productMasterActions.inActiveRow(product))
        onHideShow: isbool => dispatch(productConfigActions.onHideShow(isbool)),
        onreset: isbool => dispatch(productConfigActions.onreset(isbool))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductConfiguration)
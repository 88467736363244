import { NumberGenerationService } from '../services';
import { NumberGenerationRptConstants } from '../constants';
import { alertActions } from '.';

export const NumberGenerationRptActions = {
    getAll
};

function getAll(obj) {
    return dispatch => {
        dispatch(request());
        NumberGenerationService.fetchNumberGenerationData(obj).then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }

        );
    };
    function request() { return { type: NumberGenerationRptConstants.GETALL_REQUEST } }
    function success(datas) { return { type: NumberGenerationRptConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: NumberGenerationRptConstants.GETALL_FAILURE, error } }

}
import { mappingRptService } from '../services';
import { mappingRptConstants } from '../constants';
import { alertActions } from '.';

export const mappingRptActions = {
    getAll,
    getOUT,
    getData,
    lodingData,
    exportData
};

function getAll(obj) {
    return dispatch => {
        dispatch(request());

        mappingRptService.fetchMappingData(obj).then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }

        );
    };
    function request() { return { type: mappingRptConstants.GETALL_REQUEST } }
    function success(datas) { return { type: mappingRptConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: mappingRptConstants.GETALL_FAILURE, error } }

};
function getOUT(obj) {
    return dispatch => {
        dispatch(request());

        mappingRptService.fetchOUTData(obj).then(response => {
            dispatch(lodingData(false));;
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(lodingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }

        );
    };
    function request() { return { type: mappingRptConstants.GETALL_REQUEST } }
    function success(datas) { return { type: mappingRptConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: mappingRptConstants.GETALL_FAILURE, error } }

};

// exportData

function exportData(obj) {
    return dispatch => {
        dispatch(request());
        mappingRptService.fetchOUTData(obj).then(response => {
            dispatch(lodingData(true));
            var data = response.ResponseData;
            if (data != null) {
                data.forEach(function (v) {
                    delete v.TotalRows;
                });
            }
            dispatch(success(data))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };
    function request() { return { type: mappingRptConstants.GETEXPORT_REQUEST } }
    function success(datas) { return { type: mappingRptConstants.GETEXPORT_SUCCESS, datas } }
    function failure(error) { return { type: mappingRptConstants.GETEXPORT_FAILURE, error } }
}


function getData(rowData) {

    return dispatch => {
        dispatch(request());

        mappingRptService.fetchData(rowData).then(
            response => {
                dispatch(success(response.ResponseData));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: mappingRptConstants.GETALL_REQUEST };
    }

    function success(datas) {
        return { type: mappingRptConstants.GET_ALL_Out_SUCCESS, datas };
    }

    function failure(error) {
        return { type: mappingRptConstants.GETALL_FAILURE, error };
    }
}

function lodingData(isbool) {
    return {
        type: mappingRptConstants.LODING_REQUEST, isbool
    }
}



import { authHeader, handleResponse } from '../helpers';
export const QRDetailsRptService = {
    fetchFeedbackData,
    getReport
};

function fetchFeedbackData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}QRDetailsRpt/GetFeedbackReport`, requestOptions)
        .then(handleResponse);
}

function getReport(data) { debugger
    const requestOptions = {
        method: 'GET',
        headers: authHeader()     
    };

    return fetch(`${process.env.REACT_APP_API_URL}Reports/GetQRDetails?qrData=${data}`, requestOptions)
        .then(handleResponse);
}
import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { Dialog } from 'primereact/dialog';
import { ToastContainer, toast } from 'react-toastify';
import { mappingActions, productMasterActions } from '../../actions';
import tableRequest from '../../models/tableRequest.js';
// import Loaders from '../../templates/Loaders';
import Switch from '@material-ui/core/Switch';
import isThisHour from 'date-fns/isThisHour';

class Mapping extends React.Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        // this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.CreatedDtTemplate = this.CreatedDtTemplate.bind(this);
        this.UpdatedDtTemplate = this.UpdatedDtTemplate.bind(this);
        this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
        this.onHide = this.onHide.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.resetFormHide = this.resetFormHide.bind(this);

        this.state = {
            loading: false,
            confirm: false,
            buttonLabel: 'Submit',
            formHeading: 'Add Mapping',
            displayModal: false,
            switchAction: false,
            showSnackbar: false,
            displayConfirmation: false,
            ProductName: '',
            updateLoader: false,
            MappingId: 0,
            CreatedDt: null,
            UpdatedDt: null,
            ProductId: 0,
            ProductName: '',
            FromNo: '',
            ToNo: '',
            Qty: '',
            IsActive: true,
            formError: {
                ProductId: '',
                FromNo: '',
                ToNo: '',
                Qty: ''
            }
        };
    }
    //to trigger api on page call
    componentDidMount() {
        
        this.props.fetchApi(this.tblResponse);
        this.props.getPrdctDDL();
        this.props.getToNumber();
        this.setState({ updateLoader: true })

    }

    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }
    onPage(event) {
        this.setState({
            loading: true
        });

        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
        }, 250);
    }

    getDropValue() {
        let productId = '';
        this.props.ProductData.filter(x => {
            if (x.ProductId === this.state.ProductId) {
                productId = x.ProductId;
            }
        });

        return productId;
    }
    //to manage switch toggle confirm box
    switchToggleConfirm = (e) => {
        
        this.setState({ confirm: true, displayConfirmation: false })
        const formData = {
            MachineId: this.state.MachineId
        }
        this.props.inActiveRow(this.state.MachineId);
    }
    //to handle form
    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.formError;
        // switch (name) {
        //     case 'IsActive':
        //         value = event.target.checked;
        //         break;
        //     default:
        //         break;
        // }
        this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }
    //to validate form data
    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;

        switch (name) {
            case 'ProductId':
                if (value === 0) {
                    IsValid = false;
                    errors.ProductId = "Please select Product";
                } else errors.ProductId = null
                break;
            // case 'FromNo':
            //     if (value.trim().length < 1) {
            //         IsValid = false;
            //         errors.FromNo = "Please enter your from number.";
            //     } else if (!value.match(/^[0-9]+$/)) {
            //         IsValid = false;
            //         errors.FromNo = "Please enter only number.";
            //         this.setState({ Qty: 0 })
            //     } else {
            //         errors.FromNo = null;
            //         errors.ToNo = null;
            //         errors.Qty = null;
            //         const fromNumber = value.trim() === '' ? 0 : parseInt(value.trim());
            //         const toNumber = this.state.ToNo.trim() === '' ? 0 : parseInt(this.state.ToNo.trim());
            //         if (fromNumber >= 1 && toNumber >= 1) {
            //             if (fromNumber <= toNumber) {
            //                 const qty = (toNumber - fromNumber) + 1;
            //                 if (qty >= 0) {
            //                     this.setState({ Qty: qty })
            //                 } else {
            //                     IsValid = false;
            //                     errors.FromNo = "From number should be less than to number.";
            //                     this.setState({ Qty: 0 })
            //                 }
            //             } else {
            //                 IsValid = false;
            //                 errors.FromNo = "From number should be less than to number.";
            //                 this.setState({ Qty: 0 })
            //             }
            //         } else {
            //             // IsValid = false;
            //             // errors.FromNo = "From number should be less than to number.";
            //             this.setState({ Qty: 0 })
            //         }
            //     }
            //     break;
            case 'Qty':
                if (value.trim().length < 1) {
                    IsValid = false;
                    errors.Qty = "Please enter your quantity.";
                }
                else {
                    if (!value.match(/^[0-9]+$/)) {
                        IsValid = false;
                        errors.Qty = "Please enter only number.";
                        this.setState({ Qty: 0 })
                    } else {

                        errors.ToNo = null;
                        errors.Qty = null;
                        errors.FromNo = null;
                        const qty = value.trim() === '' ? 0 : parseInt(value.trim());
                        const fromNumber = this.props.MaxFromNo.toString().trim() === '' ? 0 : parseInt(this.props.MaxFromNo.toString().trim());
                        if (fromNumber >= 1 && qty >= 1) {
                            const toNumber = (qty + fromNumber) - 1;
                            if (toNumber >= 0) {
                                this.setState({ ToNo: toNumber })
                            } else {
                                IsValid = false;
                                errors.Qty = "To number should be greater than or equal to  from number.";
                                this.setState({ ToNo: 0 })
                            }

                        } else {
                            // IsValid = false;
                            // errors.ToNo = "To number should be greater than from number.";
                            this.setState({ ToNo: 0 })
                        }
                    }
                }
                break;

            default:
                break;
        }

        return IsValid;
    }
    //to get current date
    getCurrentDateMMDDYYY() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }
    //to submit form data
    submitForm(event) {
        
        event.preventDefault();
        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
            return isfrmvalid;
        });

        if (isfrmvalid) {
            const ddlValu = this.getDropValue();

            const formData = {
                MappingId: this.state.MappingId,
                ProductId: this.state.ProductId,
                FromNo: this.props.MaxFromNo,
                ToNo: this.state.ToNo,
                Qty: this.state.Qty,
                CreatedDt: this.state.MappingId > 0 ? this.state.CreatedDt : this.getCurrentDateMMDDYYY(),
                UpdatedDt: this.state.MappingId > 0 ? this.getCurrentDateMMDDYYY() : null,
                ProductName: ddlValu != null ? ddlValu : ""

            }
            this.setState({ updateLoader: false })

            if (this.state.MappingId == 0) {
                this.props.createMapping(formData);
                this.props.fetchApi();

                this.setState({
                    updateLoader: true
                });
            }
            else {
                this.props.updateMapping(formData);
                this.props.fetchApi();

                this.setState({
                    updateLoader: true
                });
            }
        }
    }

    //to reset form
    resetForm() {
        this.setState({
            ProductId: 0,
            MappingId: 0,
            FromNo: '',
            ToNo: '',
            Qty: '',
            formError: {
                FromNo: '',
                ToNo: '',
                Qty: '',
                ProductId: ''
            }
        });

        let errors = this.state.formError;
        Object.entries(errors).map(([key]) => {
            errors[key] = '';
            return errors[key];
        });
    }

    //to reset form on hide
    resetFormHide() {
        this.setState({
            ProductId: 0,
            MappingId: 0,
            ToNo: '',
            Qty: '',
            formError: {
                FromNo: '',
                ToNo: '',
                Qty: '',
                ProductId: ''
            }
        });

        let errors = this.state.formError;
        Object.entries(errors).map(([key]) => {
            errors[key] = '';
            return errors[key];
        });
    }
    //to hide function
    onHide() {
        this.props.onHideShow(false);
        this.resetFormHide();
    }
    openDialog() {
        this.props.onHideShow(true);
        this.setState({ buttonLabel: 'Submit', formHeading: 'Add Mapping' })

        this.props.getToNumber();
    }

    //to get format of date
    UpdatedDtTemplate(rowData) {
        if (rowData.UpdatedDt !== null) {
            return (
                <Moment format="D MMM YYYY">{rowData.UpdatedDt}</Moment>
            )
        }
    }
    //to get format of date
    CreatedDtTemplate(rowData) {
        if (rowData.CreatedDt !== null) {

            return (
                <Moment format="D MMM YYYY">{rowData.CreatedDt}</Moment>
            )
        }
    }
    //to disable checkbox
    // statusBodyTemplate(rowData) {
    //     return (
    //         <Checkbox
    //             defaultChecked
    //             disabled
    //             checked={rowData.IsActive}
    //             color="primary"
    //             inputProps={{ 'aria-label': 'secondary checkbox' }}
    //         />
    //     )
    // }    
    //to edit rowdata
    actionBodyTemplate(rowData) {
        const editMode = (e) => {
            this.setState({
                buttonLabel: 'Update',
                formHeading: 'Update Mapping',
                displayModal: true,
                ProductId: rowData.ProductId,
                MappingId: rowData.MappingId,
                FromNo: rowData.FromNo,
                ToNo: rowData.ToNo,
                Qty: rowData.Qty,
                CreatedDt: rowData.CreatedDt

            });
        }
        return (
            <Button icon="pi pi-pencil" onClick={editMode} />
        )

    }
    //to export data
    exportCSV() {
        this.dt.exportCSV();
    }

    //to render html
    render() {
        const renderFooter = (name) => {
            return (
                <div>
                    {/* <Button label="No" icon="pi pi-times" onClick={() => this.setState({ displayConfirmation: false })} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={this.switchToggleConfirm} autoFocus /> */}
                </div>
            );
        }

        const header = (
            <div className="table-header row">
                <div className="col-md-6">
                    <h4>Mapping</h4>
                </div>
                <div className="col-md-6 text-right">
                    <span className="p-input-icon-left mr-2">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" />
                    </span>
                    <Button className="mr-2" icon="pi pi-plus" label="Add" onClick={this.openDialog} />
                    {/* <Button type="button" icon="pi pi-external-link" label="Export" onClick={this.exportCSV}></Button> */}
                </div>
            </div>
        );

        return (

            <div className="datatable-filter-demo">
                {/* <Loaders loader={this.state.updateLoader} /> */}

                <ToastContainer />
                <div className="card">
                    <DataTable value={this.props.MappingData} ref={(el) => { this.dt = el; }} header={header} globalFilter={this.state.globalFilter} className="p-datatable-customers" rowHover={true} loading={this.state.loading} emptyMessage="No data found." sortMode="multiple" paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]} >
                        {/* <Column field="MappingId" header="Mapping Id" sortable></Column> */}
                        <Column field="ProductName" header="Product Name" sortable></Column>
                        <Column field="FromNo" header="From No" sortable></Column>
                        <Column field="ToNo" header="To No" sortable></Column>
                        <Column field="Qty" header="Quantity" sortable></Column>

                        {/* <Column field="CreatedDt" body={this.CreatedDtTemplate} header="Created Date" sortable></Column>
                        <Column field="UpdatedDt" body={this.UpdatedDtTemplate} header="Updated Date" sortable></Column> */}
                        {/* <Column field="Status" style={{ width: '70px' }} header="Status" body={this.statusBodyTemplate}></Column> */}
                        {/* <Column field="Action" header="Action" style={{ width: '120px' }} body={this.actionBodyTemplate}></Column> */}
                    </DataTable>
                </div>
                <Dialog header={this.state.formHeading} modal dismissableMask={false} visible={this.props.showDialog} style={{ width: '50vw' }} onHide={this.onHide}>
                    <form onSubmit={this.submitForm}>
                        <div className="row">
                            <div className="col-md-6 pt-2">
                                <FormControl variant="outlined" size="small" className="formControl col-md-12">
                                    <InputLabel id="demo-simple-select-outlined-label">Select Product*</InputLabel>
                                    <Select onChange={this.handleChange} name="ProductId" value={this.state.ProductId} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select Product">
                                        <MenuItem>
                                            <em>None</em>
                                        </MenuItem>

                                        {
                                            (typeof (this.props.ProductData) == "undefined" || this.props.ProductData == null) === true ? null : this.props.ProductData?.map(key => (
                                                <MenuItem value={key.value}>{key.label}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                {this.state.formError.ProductId !== '' ?
                                    <div className='error'>{this.state.formError.ProductId}</div> : ''}
                            </div>

                            {/* <div className="col-md-6 pt-3">
                                <TextField onChange={this.handleChange} value={this.state.FromNo} name="FromNo" size="small" variant="outlined" className="fullwidth" label="From No*" />
                                {this.state.formError.FromNo !== '' ?
                                    <div className='error'>{this.state.formError.FromNo}</div> : ''}
                            </div> */}
                            <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} value={this.props.MaxFromNo} name="FromNo" size="small" variant="outlined" className="fullwidth" label="From No*" />
                                {/* {this.state.formError.FrmNo !== '' ?
                                    <div className='error'>{this.state.formError.FrmNo}</div> : ''} */}
                            </div>
                            {/* <div className="col-md-6 pt-3">
                                <TextField onChange={this.handleChange} value={this.state.ToNo} name="ToNo" size="small" variant="outlined" className="fullwidth" label="To No*" />
                                {this.state.formError.ToNo !== '' ?
                                    <div className='error'>{this.state.formError.ToNo}</div> : ''}
                            </div> */}
                            <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} disabled value={this.state.ToNo} name="ToNo" size="small" variant="outlined" className="fullwidth" label="To No*" />
                                {/* {this.state.formError.ToNo !== '' ?
                                    <div className='error'>{this.state.formError.ToNo}</div> : ''} */}
                            </div>
                            <div className="col-md-6 pt-3">
                                <TextField onChange={this.handleChange} value={this.state.Qty} name="Qty" size="small" variant="outlined" className="fullwidth" label="Qty*" />
                                {this.state.formError.Qty !== '' ?
                                    <div className='error'>{this.state.formError.Qty}</div> : ''}
                            </div>
                            <div className="col-md-12 mt-4 text-center">
                                <Button label={this.state.buttonLabel} icon="pi pi-check" />
                            </div>
                            <div class="col-md-12">
                                Note: Fields marked as (*) are required
                            </div>
                        </div>
                    </form>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={renderFooter('displayConfirmation')} onHide={() => this.setState({ displayConfirmation: false })}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3 confirm-icon" style={{ fontSize: '2rem' }} />
                        <span>Are you sure you want to InActive?</span>
                    </div>
                </Dialog>

            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        MappingData: state.mapping.items,
        ProductData: (typeof (state.product.prdctddl) !== "undefined" && state.product.prdctddl !== null) === true ? state.product?.prdctddl : null,

        MaxFromNo: state.mapping?.issueData?.ToNo == null ? 1 : (state.mapping?.issueData?.ToNo + 1),
        showDialog: state.mapping.showDialog
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (obj) => {
            dispatch(mappingActions.getAll(obj))
        },
        fetchProductApi: (obj) => {
            dispatch(productMasterActions.getProductName(obj))
        },
        getToNumber: () => {
            dispatch(mappingActions.GetToNo())
        },
        createMapping: machine => dispatch(mappingActions.createMapping(machine)),
        updateMapping: machine => dispatch(mappingActions.updateMapping(machine)),
        onHideShow: isbool => dispatch(mappingActions.onHideShow(isbool)),
        getPrdctDDL: () => dispatch(productMasterActions.getPrdctDDL())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Mapping)
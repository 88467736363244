import { roleMasterConstants } from '../constants';

export function role(state = {}, action) {
    switch (action.type) {
        case roleMasterConstants.DIALOG_REQUEST:
            return {
                ...state,
                showDialog: action.isbool
            };
            case roleMasterConstants.UPDATE_PAGE_STORE:
            return {
                ...state,
                pages: action.pageList
            };
        case roleMasterConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case roleMasterConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case roleMasterConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case roleMasterConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            };
        case roleMasterConstants.CREATE_SUCCESS:
            return {
                ...state,
                items: state.items == null ? [action.data] : [action.data, ...state.items]
            };
        case roleMasterConstants.CREATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case roleMasterConstants.UPDATE_REQUEST:
            return {
                ...state,
                items: state.items.map(item =>
                    item.RoleId === action.data.RoleId ? { ...item, updating: true } : item
                )
            };
        case roleMasterConstants.UPDATE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.RoleId === action.data.RoleId ? action.data : item
                )
            };
        case roleMasterConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case roleMasterConstants.DELETE_REQUEST:
            return {
                ...state,
                deleting: true
            };
        case roleMasterConstants.DELETE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.RoleId === action.data ? {
                        CompanyId: item.CompanyId,
                        CompanyName: item.CompanyName,
                        roleId: item.roleId,
                        RoleName: item.roleName,
                        RoleDesc: item.RoleDesc,
                        isActive: item.isActive,
                        CreatedDate: item.CreatedDate
                    } : item
                )
            };
        case roleMasterConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error
            };
            case roleMasterConstants.GETROLEDDL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case roleMasterConstants.GETROLEDDL_SUCCESS:
            return {
                ...state,
                rollddl: action.datas
            };
            case roleMasterConstants.GETROLEDDL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case roleMasterConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
            case roleMasterConstants.GET_PAGES_REQUEST:
                return {
                    ...state,
                    getpages: true
                };
            case roleMasterConstants.GET_PAGES_SUCCESS:
                return {
                    ...state,
                    pages: action.pages
                };
            case roleMasterConstants.GET_PAGES_FAILURE:
                return {
                    ...state,
                    error: action.error
                };
        default:
            return state
    }
}
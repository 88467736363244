import { authHeader, handleResponse } from '../helpers';
export const loginService = {
    checkUser
    

};

function checkUser(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Login/CheckUser`, requestOptions)
        .then(handleResponse);
}


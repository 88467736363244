import { FeedbackRptService } from '../services';
import { FeedbackRptConstants } from '../constants';
import { alertActions } from '.';

export const FeedbackRptActions = {
    getAll,
    exportData,
    lodingData
};

function getAll(obj) {
    return dispatch => {
        dispatch(request());

        FeedbackRptService.fetchFeedbackData(obj).then(response => {
            dispatch(lodingData(false));;
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(lodingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }

        );
    };
    function request() { return { type: FeedbackRptConstants.GETALL_REQUEST } }
    function success(datas) { return { type: FeedbackRptConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: FeedbackRptConstants.GETALL_FAILURE, error } }
}


function exportData(obj) {
    return dispatch => {
        dispatch(request());
        FeedbackRptService.fetchFeedbackData(obj).then(response => {
            dispatch(lodingData(false));
            var data = response.ResponseData;
            if (data != null) {
                data.forEach(function (v) {
                    delete v.TotalRows;
                });
            }
            dispatch(success(data))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };
    function request() { return { type: FeedbackRptConstants.GETEXPORT_REQUEST } }
    function success(datas) { return { type: FeedbackRptConstants.GETEXPORT_SUCCESS, datas } }
    function failure(error) { return { type: FeedbackRptConstants.GETEXPORT_FAILURE, error } }
}

function lodingData(isbool) {
    return {
        type: FeedbackRptConstants.LODING_REQUEST, isbool
    }
}


import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import TextField from "@material-ui/core/TextField";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { loginActions, alertActions, changePasswordActions } from "../actions";
import logo from "./../assets/images/Honda_logo.png";
import largeLogo from "./../assets/images/honda-large-logo.svg";
import carLogo from "./../assets/images/HondaCarLogo.png";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: null,
      Password: null,
      loggedIn: false,
      eyeToggle: true,
      forgotModal: false,
      formError: {
        UserName: "",
        Password: "",
      },
      formForgotHeading: "Forgot Password",
      buttonLabel: "Submit",
      Email: "",
      forgotFormError: {
        Email: null,
      },
    };
    this.formHandle = this.formHandle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.passwordToggle = this.passwordToggle.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.handleForgotChange = this.handleForgotChange.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.submitForgotForm = this.submitForgotForm.bind(this);
  }

  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "IsActive":
        value = event.target.checked;
        break;
      default:
        break;
    }
    this.formHandle(value, name);
    this.setState({ errors, [name]: value });
  }
  formHandle(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "UserName":
        if (value === null || value.length < 1) {
          errors.UserName = "Please enter your email-id.";
        } else if (value.length >= 1) {
          /* eslint-disable */
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            errors.userName = "Please enter valid email-id.";
          } else errors.UserName = null;
          /* eslint-enable */
        } else errors.UserName = null;
        break;
      case "Password":
        errors.Password = value.length < 1 ? "Please enter password" : null;
        break;
      default:
        break;
    }
    return IsValid;
  }
  passwordToggle() {
    this.setState({ eyeToggle: !this.state.eyeToggle });
  }
  // submitForm(event) {
  //     event.preventDefault();
  //     const formdata = {
  //     Email: this.state.UserName,
  //     Password: this.state.Password
  //   }
  //   this.props.checkUser(formdata);

  // }
  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.formHandle(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      const formData = {
        Email: this.state.UserName,
        Password: this.state.Password,
      };
      this.props.checkUser(formData);
    }
  }

  //to reset form
  formForgotReset() {
    this.setState({ Email: null });
    let errors = this.state.forgotFormError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
      return errors[key];
    });
  }
  //to open dialog
  openDialog() {
    this.setState({ visible: true });
    this.formForgotReset();
  }
  //to hide function
  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
    });
    this.formForgotReset();
  }
  //to submit form
  submitForgotForm(event) {
    event.preventDefault();

    let isfrmvalid = true;
    let checkerror = this.state.forgotFormError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForgotForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      const formData = {
        Email: this.state.Email,
      };

      this.props.forgotPassword(formData);
    }
  }
  //to handle change
  handleForgotChange(event) {
    const { name, value } = event.target;
    let errors = this.state.forgotFormError;

    this.validateForgotForm(value, name);
    this.setState({ errors, [name]: value });
  }
  //to validate form
  validateForgotForm(value, name) {
    let errors = this.state.forgotFormError;
    let IsValid = true;

    switch (name) {
      case "Email":
        if (value == null || value.length < 1) {
          IsValid = false;
          errors.Email = "Please enter your email-id.";
        } else if (value.length >= 1) {
          /* eslint-disable */
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.Email = "Please enter valid email-id.";
          } else errors.Email = "";
          /* eslint-enable */
        } else errors.Email = "";
        break;
      default:
        break;
    }

    return IsValid;
  }
  render() {
    return (
      <>
        <div className="login-wraper2">
          <div className="leftContainer">
            <div className="login-logo">
              <img src={carLogo} />
            </div>
            <h3>Authentication Solution for Honda Car India </h3>
            <h4>Powered by Holostik India Limited</h4>
          </div>
          <div className="login-container row">
            <div className="form-wraper col-md-12">
              {/* <div className="login-logo"><img src={largeLogo} /></div> */}
              <form onSubmit={this.submitForm}>
                <div className="heading">Sign In</div>
                <div className="form-group">
                  <span>
                    <i className="fa fa-user"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Email Id"
                    onChange={this.handleChange}
                    name="UserName"
                    className="form-control"
                    noValidate
                  />
                  <span className="focus-input100"></span>
                  {this.state.formError.UserName != null ? (
                    <div className="error">{this.state.formError.UserName}</div>
                  ) : null}
                </div>

                <div className="form-group">
                  <span>
                    <i className="fa fa-lock"></i>
                  </span>
                  <input
                    type={this.state.eyeToggle ? "password" : "text"}
                    autoComplete="off"
                    onChange={this.handleChange}
                    placeholder="Password"
                    name="Password"
                    className="form-control"
                    noValidate
                  />
                  <span className="focus-input100"></span>
                  <i
                    className={
                      this.state.eyeToggle ? "pi pi-eye" : "pi pi-eye-slash"
                    }
                    onClick={this.passwordToggle}
                  ></i>
                  {this.state.formError.Password != null ? (
                    <div className="error">{this.state.formError.Password}</div>
                  ) : null}
                </div>

                {/* <div
                  className="forgot-pass text-right"
                  onClick={() => this.setState({ forgotModal: true })}
                >
                  Forgot Password
                </div> */}

                <div>
                  <button type="submit" className="btn btn-primary">
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <Dialog
          header="Forgot Password"
          visible={this.state.forgotModal}
          style={{ width: "50vw" }}
          onHide={() => this.setState({ forgotModal: false })}
        >
          <h5 style={{ color: "#3F51B5" }}>Reset Password For C&S Electric</h5>
          <p>Enter your Email and instructions will be sent to you!</p>
          <input
            type="text"
            placeholder="Email Id"
            onChange={this.handleForgotChange}
            name="Email"
            className="form-control"
            noValidate
          />
          <div className="col-md-12 mt-3 text-center">
            <Button
              label="Reset"
              icon="pi pi-check"
              onClick={this.submitForgotForm}
            />
          </div>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkUser: (login) => dispatch(loginActions.checkUser(login)),
    forgotPassword: (data) =>
      dispatch(changePasswordActions.forgotPassword(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { Dialog } from 'primereact/dialog';
import { ToastContainer, toast } from 'react-toastify';
import { issueNoActions, demappingActions } from '../../actions';
import tableRequest from '../../models/tableRequest.js';
// import Loaders from '../../templates/Loaders';
import Switch from '@material-ui/core/Switch';

class IssueNumber extends React.Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        // this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        // this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.getLazyLoadingValues = this.getLazyLoadingValues.bind(this)
        this.exportCSV = this.exportCSV.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.CreatedDtTemplate = this.CreatedDtTemplate.bind(this);
        this.UpdatedDtTemplate = this.UpdatedDtTemplate.bind(this);
        this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.state = {
            rows: 10,
            loading: false,
            confirm: false,
            buttonLabel: 'Submit',
            formHeading: 'Add Issue No',
            displayModal: false,
            switchAction: false,
            showSnackbar: false,
            displayConfirmation: false,
            Name: '',
            updateLoader: false,
            IssueId: 0,
            CreatedDt: null,
            UpdatedDt: null,
            PlantId: 0,
            BatchNo: '',
            FromNo: '',
            ToNo: '',
            Qty: '',
            SupplierId: '',
            IsActive: true,
            totalRecords: 0,
            refrenceFromNo: '',
            refrenceToNo: '',
            formError: {
                PlantId: null,
                BatchNo: null,
                FromNo: '',
                ToNo: '',
                Qty: '',
                CreatedDt: null,
                UpdatedDt: null
            }
        };
    }
    //to trigger api on page call
    componentDidMount() {
        this.props.fetchApi(this.tblResponse);
        //this.props.fetchFromNumberApi(this.tblResponse);
        this.setState({ updateLoader: true })
        this.props.getBatchNoDDL();
        this.props.getPlantDDL();

    }
    onChangeBatch(event) {
        this.props.getBatchDetails(event.target.value);
    }

    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }
    searchFilter(e) {
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);
    }
    onPage(event) {
        this.setState({
            loading: true
        });
        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
            setTimeout(() => {
                this.setState({
                    loading: false
                });
            }, 500);
        }, 250);
    }

    getDropValue() {
        let productId = '';
        this.props.ProductData.filter(x => {
            if (x.ProductId === this.state.ProductId) {
                productId = x.ProductId;
            }
        });

        return productId;
    }
    //to manage switch toggle confirm box
    switchToggleConfirm = (e) => {

        this.setState({ confirm: true, displayConfirmation: false })
        const formData = {
            MachineId: this.state.MachineId
        }
        this.props.inActiveRow(this.state.MachineId);
    }

    //to handle form
    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.formError;
        // switch (name) {
        //     case 'IsActive':
        //         value = event.target.checked;
        //         break;
        //     default:
        //         break;
        // }
        this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }
    //to validate form data
    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;
        ;
        switch (name) {
            case 'BatchNo':
                if (!value) {
                    IsValid = false;
                    errors.BatchNo = "Please select batch number.";
                } else errors.BatchNo = ''
                break;
            case 'PlantId':
                if (!value) {
                    IsValid = false;
                    errors.PlantId = "Please select plant.";
                } else errors.PlantId = ''
                break;
            // case 'FrmNo':
            //     if (value.length < 1) {
            //         IsValid = false;
            //         errors.FrmNo = "Please enter FrmNo.";
            //     } else errors.FrmNo = ''
            //     break;

            // case 'ToNo':
            // if (value.length < 1) {
            //     IsValid = false;
            //     errors.ToNo = "Please enter ToNo.";
            // } else errors.ToNo = ''
            // break;
            // case 'IssueQty':
            //     if (value.trim().length < 1) {
            //         IsValid = false;
            //         errors.IssueQty = "Please enter your quantity.";
            //     }
            //     else {
            //         if (!value.match(/^[0-9]+$/)) {
            //             IsValid = false;
            //             errors.IssueQty = "Please enter only number.";
            //             this.setState({ IssueQty: 0 })
            //         } else {

            //             errors.ToNo = null;
            //             errors.IssueQty = null;
            //             errors.FromNo = null;
            //             const qty = value.trim() === '' ? 0 : parseInt(value.trim());
            //             const fromNumber = this.props.MaxFromNo.toString().trim() === '' ? 0 : parseInt(this.props.MaxFromNo.toString().trim());
            //             if (fromNumber >= 1 && qty >= 1) {
            //                 const toNumber = (qty + fromNumber) - 1;
            //                 if (toNumber >= 0) {
            //                     this.setState({ ToNo: toNumber })
            //                 } else {
            //                     IsValid = false;
            //                     errors.IssueQty = "To number should be greater than or equal to  from number.";
            //                     this.setState({ ToNo: 0 })
            //                 }

            //             } else {
            //                 // IsValid = false;
            //                 // errors.ToNo = "To number should be greater than from number.";
            //                 this.setState({ ToNo: 0 })
            //             }
            //         }
            //     }
            //     break;
            // case 'SalesOrderNo':
            //     if (value.length < 1) {
            //         IsValid = false;
            //         errors.SalesOrderNo = "Please enter sales order no.";
            //     } else errors.SalesOrderNo = ''
            //     break; 
            case 'FromNo':
                if (value < 1) {
                    IsValid = false;
                    errors.FromNo = "Please enter your from number.";
                } else {
                    if (!value.toString().match(/^[0-9]+$/)) {
                        IsValid = false;
                        errors.FromNo = "Please enter only number.";
                        this.setState({ Qty: 0 })
                    } else {
                        errors.FromNo = null;
                        errors.ToNo = null;
                        errors.Qty = null;
                        const fromNumber = value.toString() === '' ? 0 : parseInt(value.toString());
                        const toNumber = this.state.ToNo === '' ? 0 : parseInt(this.state.ToNo);
                        if (fromNumber >= this.state.refrenceFromNo && fromNumber <= this.state.refrenceToNo && toNumber >= this.state.refrenceFromNo && toNumber <= this.state.refrenceToNo) {
                            if (fromNumber <= toNumber) {
                                const qty = (toNumber - fromNumber) + 1;
                                if (qty >= 0) {
                                    this.setState({ Qty: qty })
                                } else {
                                    IsValid = false;
                                    errors.FromNo = "From No. should be less than to number.";
                                    this.setState({ Qty: 0 })
                                }
                            } else {
                                IsValid = false;
                                errors.FromNo = "From No. should be less than to number.";
                                this.setState({ Qty: 0 })
                            }
                        } else {
                            IsValid = false;
                            errors.FromNo = "From No. should be less than To no. and numbers must exist between mapped series";
                            this.setState({ Qty: 0 })
                        }
                    }
                }
                break;
            case 'ToNo':

                if (value < 1) {
                    IsValid = false;
                    errors.ToNo = "Please enter your to number.";
                } else {
                    if (!value.toString().match(/^[0-9]+$/.exec(value))) {
                        IsValid = false;
                        errors.FromNo = "Please enter only number.";
                        this.setState({ Qty: 0 })
                    } else {
                        errors.FromNo = null;
                        errors.ToNo = null;
                        errors.Qty = null;
                        const toNumber = value.toString() === '' ? 0 : parseInt(value.toString());
                        const fromNumber = this.state.FromNo === '' ? 0 : parseInt(this.state.FromNo);
                        if (fromNumber >= this.state.refrenceFromNo && fromNumber <= this.state.refrenceToNo && toNumber >= this.state.refrenceFromNo && toNumber <= this.state.refrenceToNo) {
                            if (fromNumber <= toNumber) {
                                const qty = (toNumber - fromNumber) + 1;
                                if (qty >= 0) {
                                    this.setState({ Qty: qty })
                                } else {
                                    IsValid = false;
                                    errors.ToNo = "To No. should be greater than from number.";
                                    this.setState({ Qty: 0 })
                                }
                            } else {
                                IsValid = false;
                                errors.ToNo = "To No. should be greater than from number.";
                                this.setState({ Qty: 0 })
                            }
                        } else {
                            IsValid = false;
                            errors.ToNo = "To No. should be greater than from no. and numbers must exist between mapped series.";
                            this.setState({ Qty: 0 })
                        }
                    }
                }
                break;
            case 'Qty':
                if (value < 1) {
                    IsValid = false;
                    errors.Qty = "Please enter your quantity";
                }
                else errors.Qty = null;
                break;

            default:
                break;
        }

        return IsValid;
    }
    //to get current date
    getCurrentDateMMDDYYY() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }
    //to submit form data
    submitForm(event) {

        event.preventDefault();
        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
            return isfrmvalid;
        });

        if (isfrmvalid) {
            const formData = {
                IssueId: this.state.IssueId,
                FromNo: this.state.FromNo,
                ToNo: this.state.ToNo,
                IssueQty: this.state.Qty,
                PlantId: this.state.PlantId,
                BatchNo: this.state.BatchNo,
                SupplierId: this.state.SupplierId,
                CreatedDt: this.state.IssueId > 0 ? this.state.CreatedDt : this.getCurrentDateMMDDYYY(),
                UpdatedDt: this.state.IssueId > 0 ? this.getCurrentDateMMDDYYY() : null
            }
            this.setState({ updateLoader: false })


            //console.log('formData', formData);
            if (this.state.IssueId == 0) {
                this.props.createIssueNo(formData);
                this.props.fetchApi();

                this.setState({
                    updateLoader: true
                });
            }
        }
    }
    //to reset form
    resetForm() {
        this.setState({
            Qty: '',
            IssueId: 0,
            FromNo: '',
            ToNo: '',
            BatchNo: '',
            PlantId: '',
            SupplierId: '',
            refrenceFromNo: '',
            refrenceToNo: '',
            formError: {
                FrmNo: '',
                ToNo: '',
                Qty: '',
                BatchNo: '',
                PlantId: '',
            }
        });

        let errors = this.state.formError;
        Object.entries(errors).map(([key]) => {
            errors[key] = '';
            return errors[key];
        });
    }
    //to hide function
    onHide() {
        this.resetForm();
        this.props.onHideShow(false);
    }

    openDialog() {
        this.props.onHideShow(true);
        this.resetForm();
        this.setState({ buttonLabel: 'Submit', formHeading: 'Add Issue No' })
    }

    //to get format of date
    UpdatedDtTemplate(rowData) {
        if (rowData.UpdatedDt !== null) {
            return (
                <Moment format="D MMM YYYY">{rowData.UpdatedDt}</Moment>
            )
        }
    }
    //to get format of date
    CreatedDtTemplate(rowData) {
        if (rowData.CreatedDt !== null) {

            return (
                <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>
            )
        }
    }
    //to disable checkbox
    // statusBodyTemplate(rowData) {
    //     return (
    //         <Checkbox
    //             defaultChecked
    //             disabled
    //             checked={rowData.IsActive}
    //             color="primary"
    //             inputProps={{ 'aria-label': 'secondary checkbox' }}
    //         />
    //     )
    // }    
    //to edit rowdata

    //to export data
    exportCSV() {
        this.dt.exportCSV();
    }

    componentDidUpdate(prevProps) {
        if (this.props.IssueData) {
            if (prevProps.IssueData !== this.props.IssueData) {
                // this.props.myProp has a different value
                if (this.props.IssueData) {
                    var e = this.props.IssueData;
                    this.setState({
                        totalRecords: e[0].TotalRows
                    });
                }
            }
        } else {
            if (this.state.totalRecords !== 0)
                this.setState({
                    totalRecords: 0,
                    first: 0
                });
        }
    }

    onSort(event) {
        this.setState({ sortOrder: this.state?.sortOrder === 1 ? 2 : 1 });
        const sortField = event.multiSortMeta[0].field === "CreatedDate" ? "CreatedDate" : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state?.sortOrder === 1 ? 'DESC' : 'ASC';//this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);
    }


    //to render html
    render() {
        const renderFooter = (name) => {
            return (
                <div>
                    {/* <Button label="No" icon="pi pi-times" onClick={() => this.setState({ displayConfirmation: false })} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={this.switchToggleConfirm} autoFocus /> */}
                </div>
            );
        }

        const header = (
            <div className="table-header row">
                <div className="col-md-6">
                    <h4>Issue Number</h4>
                </div>
                <div className="col-md-6 text-right">
                    <span className="p-input-icon-left mr-2">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" />
                    </span>
                    {/* <Button className="mr-2" icon="pi pi-plus" label="Add" onClick={(e) => this.openDialog(e)} /> */}
                    {/* <Button type="button" icon="pi pi-external-link" label="Export" onClick={this.exportCSV}></Button> */}
                </div>
            </div>
        );

        return (
            <div className="datatable-filter-demo">
                {/* <Loaders loader={this.state.updateLoader} /> */}
                <ToastContainer />
                <div className="card">
                    <DataTable value={this.props.IssueData} ref={(el) => { this.dt = el; }} header={header} globalFilter={this.state.globalFilter} className="p-datatable-customers" rowHover={true} loading={this.state.loading} emptyMessage="No data found." sortMode="multiple" paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={this.state.rows} style={{ width: '100%' }}
                        rowsPerPageOptions={[10, 20, 50]}
                        totalRecords={parseInt(this.state.totalRecords)}
                        lazy={true} first={this.state.first}
                        onPage={this.onPage} onSort={this.onSort}>

                        {/* <Column field="IssueId" header="Issue Id" sortable></Column> */}
                        {/* <Column field="Supplier" header="Supplier Name" sortable></Column>
                        <Column field="BatchNo" header="Batch No" sortable></Column> */}
                        <Column field="FromNo" header="From No" sortable></Column>
                        <Column field="ToNo" header="To No" sortable></Column>
                        <Column field="IssueQty" header="Quantity" sortable></Column>
                        {/* <Column field="PlantName" header="Plant" sortable></Column> */}
                        <Column field="CreatedDate" body={this.CreatedDtTemplate} header="Created Date" sortable></Column>
                        {/*<Column field="UpdatedDt" body={this.UpdatedDtTemplate} header="Updated Date" sortable></Column> */}
                        {/* <Column field="Status" style={{ width: '70px' }} header="Status" body={this.statusBodyTemplate}></Column> */}
                        {/* <Column field="Action" header="Action" style={{ width: '120px' }} body={this.actionBodyTemplate}></Column> */}
                    </DataTable>
                </div>
                <Dialog header={this.state.formHeading} modal dismissableMask={false} visible={this.props.showDialog} style={{ width: '50vw' }} onHide={this.onHide}>
                    <form onSubmit={this.submitForm}>
                        <div className="row">
                            <div className="col-md-6 pt-2">
                                <FormControl variant="outlined" size="small" className="formControl col-md-12">
                                    <InputLabel id="demo-simple-select-outlined-label">Select Batch Number</InputLabel>
                                    <Select onChange={(e) => { this.handleChange(e); this.onChangeBatch(e); }} name="BatchNo" value={this.state.BatchNo} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select Batch Number">
                                        <MenuItem>
                                            <em>None</em>
                                        </MenuItem>

                                        {this.props.BatchData == (undefined || null) ? null : this.props.BatchData.map(key => (
                                            <MenuItem value={key.value}>{key.label}</MenuItem>)
                                        )}
                                    </Select>
                                </FormControl>
                                {this.state.formError.BatchNo !== '' ?
                                    <div className='error'>{this.state.formError.BatchNo}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-2">
                                <FormControl variant="outlined" size="small" className="formControl col-md-12">
                                    <InputLabel id="demo-simple-select-outlined-label">Select Plant</InputLabel>
                                    <Select onChange={this.handleChange} name="PlantId" value={this.state.PlantId} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select Plant">
                                        <MenuItem>
                                            <em>None</em>
                                        </MenuItem>

                                        {this.props.PlantData == (undefined || null) ? null : this.props.PlantData.map(key => (
                                            <MenuItem value={key.value}>{key.label}</MenuItem>)
                                        )}
                                    </Select>
                                </FormControl>
                                {this.state.formError.PlantId !== '' ?
                                    <div className='error'>{this.state.formError.PlantId}</div> : ''}
                            </div>
                            {/* <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} value={this.state.SalesOrderNo} name="SalesOrderNo" size="small" variant="outlined" className="fullwidth" label="Sales Order No*" />
                                {this.state.formError.SalesOrderNo !== '' ?
                                    <div className='error'>{this.state.formError.SalesOrderNo}</div> : ''}
                            </div> */}
                            <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} value={this.state.FromNo} name="FromNo" size="small" variant="outlined" className="fullwidth" label="From No*" />
                                {this.state.formError.FromNo !== '' ?
                                    <div className='error'>{this.state.formError.FromNo}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} value={this.state.ToNo} name="ToNo" size="small" variant="outlined" className="fullwidth" label="To No*" />
                                {this.state.formError.ToNo !== '' ?
                                    <div className='error'>{this.state.formError.ToNo}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} value={this.state.Qty} disabled name="Qty" size="small" variant="outlined" className="fullwidth" label="IssueQty*" />
                                {this.state.formError.Qty !== '' ?
                                    <div className='error'>{this.state.formError.Qty}</div> : ''}
                            </div>
                            <div className="col-md-12 mt-3 mb-3 text-center">
                                <Button label={this.state.buttonLabel} icon="pi pi-check" />
                            </div>
                            <div className="col-md-12">
                                Note: Fields marked as (*) are required
                            </div>
                        </div>
                    </form>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={renderFooter('displayConfirmation')} onHide={() => this.setState({ displayConfirmation: false })}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3 confirm-icon" style={{ fontSize: '2rem' }} />
                        <span>Are you sure you want to InActive?</span>
                    </div>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        IssueData: state.issueNo.items,
        MaxFromNo: state.demapping?.items?.FrmNo,
        BatchData: state.issueNo.batchddl,
        PlantData: state.issueNo.plantddl,
        BatchDetails: state.issueNo.batchDetails,
        showDialog: state.issueNo.showDialog
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (obj) => {
            dispatch(issueNoActions.getAll(obj))
        },
        fetchFromNumberApi: (obj) => {
            dispatch(demappingActions.getFromNumber(obj))
        },
        createIssueNo: machine => dispatch(issueNoActions.createIssueNo(machine)),
        getBatchNoDDL: () => dispatch(issueNoActions.getBatchNumberDDL()),
        getPlantDDL: () => dispatch(issueNoActions.getPlantDDL()),
        getBatchDetails: (BatchNo) => dispatch(issueNoActions.getBatchDetails(BatchNo)),
        onHideShow: isbool => dispatch(issueNoActions.onHideShow(isbool))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IssueNumber)
import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { Dialog } from "primereact/dialog";
import VerifyProduct from "../models/VerifyProduct";
import { productAuthenticationActions } from "../actions";
import { handleResponse } from "../helpers";
import reviewIcon from "./../assets/images/write-review-icon.png";
import appHome from "./../assets/images/app.png";
import shareIcon from "./../assets/images/share-icon.png";
import sucessIcon from "./../assets/images/sucess-code.png";
import fakeIcon from "./../assets/images/wrong-code.png";
import logo from "./../assets/images/Honda_logo.png";
import { Rating } from "primereact/rating";

export class ProductAuthentication extends Component {
  constructor(props) {
    super(props);
    this.clsVerifyProduct = new VerifyProduct();
    this.state = {
      formIsValid: false,
      IsLocation: false,
      ratingBox: false,
      shareBox: false,
      VerifyProduct: {},
      rating: null,
      showDialog: false,
      VerificationMode: "Web",
      QrData: window.location.href,
      VerificationType: 2,
      VerificationId: 0,
      displayBasic: false,
      UserId: "",
      SeqNo: "",
      Name: "",
      email: "",
      MobileNo: "",
      UserFeedback: "",
      formError: {
        Name: null,
        email: null,
        MobileNo: null,
        UserFeedback: null,
      },
    };

    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.onHide = this.onHide.bind(this);
    this.reverseGeocodeLatLngAndVerifyProduct =
      this.reverseGeocodeLatLngAndVerifyProduct.bind(this);
    const urlParams = new URLSearchParams(window.location.search);
    const parmUid = urlParams.get("uid");
    //const curl = window.location.href;
    this.clsVerifyProduct.QrData = this.state.QrData;
    this.clsVerifyProduct.VerificationType = this.state.VerificationType;
    this.clsVerifyProduct.VerificationMode = this.state.VerificationMode;
    //this.findMe();
  }

  //get reverse geo code
  reverseGeoCode(lat, long) {
    // tslint:disable-next-line:max-line-length
    const requestOptions = {
      method: "GET",
    };
    return fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`,
      requestOptions
    ).then(handleResponse);
    //   return fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`);
  }

  //to trigger api on page call
  componentDidMount() {
    const caches = window.caches;
    if (window.caches) {
      window.caches.keys().then(async function (names) {
        await Promise.all(names.map((name) => window.caches.delete(name)));
      });
    }

    // const urlParams = new URLSearchParams(window.location.search);
    // const parmUid = urlParams.get('uid');

    // const curl = window.location.href;

    // this.clsVerifyProduct.verificationMode = this.state.verificationMode;
    // this.clsVerifyProduct.qrData = this.state.qrData;
    // this.clsVerifyProduct.verificationType = this.state.verificationType;
    // this.clsVerifyProduct.UID = parmUid;

    // setTimeout(() => {
    //     // this.findMe();
    // }, 1000);
    // this.findMe();
  }

  // get verify product list data
  GetVerifyProduct(productAuthReq) {
    this.setState({
      IsLocation: false,
    });

    this.props.verifyProduct(productAuthReq);
  }
  //to hide function
  hideFunc() {
    this.setState({
      displayBasic: false,
    });
  }

  //to validate feedback form
  validateFeedback(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "Name":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.Name = "Please enter your name.";
        } else if (value.length >= 1) {
          if (!value.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
            IsValid = false;
            errors.Name = "Please enter only alphabet.";
          } else errors.Name = "";
        } else errors.Name = "";
        break;
      case "email":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.email = "Please enter your email-id.";
        } else if (value && value.length > 1) {
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.email = "Please enter valid email-id.";
          } else errors.email = "";
        } else errors.email = "";
        break;
      case "MobileNo":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.MobileNo = "Please enter your mobile no.";
        } else if (value && value.length > 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = false;
            errors.MobileNo = "Please enter valid mobile no.";
          } else errors.MobileNo = null;
        } else errors.MobileNo = "";
        break;
      case "UserFeedback":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.UserFeedback = "Please enter your feedback.";
        } else errors.UserFeedback = "";
        break;

      default:
        break;
    }

    return IsValid;

    // let errors = this.state.formError;
    // let formFeedIsValid = true;

    // switch (name) {
    //     case 'Name':
    //         if (value === null || value.length < 1) {
    //             formFeedIsValid = false;
    //             errors.Name = "Please enter your name.";
    //         } else if (value.length >= 1) {
    //             if (!value.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
    //                 formFeedIsValid = false;
    //                 errors.Name = "Please enter only alphabet.";
    //             } else errors.Name = null
    //         } else errors.Name = null
    //         break;
    //     case 'email':
    //         if (value === null || value === '' || value.length >= 1) {
    //             /* eslint-disable */
    //             if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    //                 formFeedIsValid = false;
    //                 errors.email = "Please enter valid email-id.";
    //             } else errors.email = null
    //             /* eslint-enable */
    //         } else errors.email = null
    //         break;
    //     case 'MobileNo':
    //         if (value === null || value.length < 1) {
    //             formFeedIsValid = false;
    //             errors.MobileNo = "Please enter your mobile no.";
    //         }
    //         else if (value.length >= 1) {
    //             if (!value.match(/^[6-9]\d{9}$/)) {
    //                 formFeedIsValid = false;
    //                 errors.MobileNo = "Please enter valid mobile no.";
    //             } else errors.MobileNo = null
    //         } else errors.MobileNo = null
    //         break;
    //     case 'UserFeedback':
    //         if (value === null || value.length < 1) {
    //             formFeedIsValid = false;
    //             errors.UserFeedback = "Please enter your feedback.";
    //         } else errors.UserFeedback = null
    //         break;
    //     default:
    //         break;
    // }

    // return formFeedIsValid;
  }

  submitForm(event) {
    // const { name, value } = event.target;
    event.preventDefault();

    let isfeedfrmvalid = true;
    let checkerror = this.state.formError;
    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateFeedback(val, key);
      if (!valid) {
        this.setState({ checkerror: isfeedfrmvalid });
        isfeedfrmvalid = false;
      }

      return isfeedfrmvalid;
    });

    const feedbackData = {
      Name: this.state.Name,
      email: this.state.email,
      MobileNo: this.state.MobileNo,
      UserFeedback: this.state.UserFeedback,
      VerificationId: this.props.ProductAuthenticationData.VerificationId,
    };

    if (isfeedfrmvalid) {
      this.props.UpdateFeedback(feedbackData);
    }

    this.setState({ [feedbackData]: "" });
  }

  formReset() {
    this.setState({
      Name: "",
      email: "",
      MobileNo: "",
      UserFeedback: "",
      formError: {
        Name: null,
        email: null,
        MobileNo: null,
        UserFeedback: null,
      },
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.scratchError;

    this.validateFeedback(value, name);
    this.setState({ errors, [name]: value });
  }
  onHide() {
    this.setState({ showDialog: false });
  }
  //to find  location
  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.showPosition(position);
        },
        (denied) => {
          // alert(JSON.stringify(denied));
          this.GetVerifyProduct(this.clsVerifyProduct);
        },
        { enableHighAccuracy: true, maximumAge: 10000 }
      );
    } else {
      this.GetVerifyProduct(this.clsVerifyProduct);
    }
  }
  //to get exact longitude and latitude
  showPosition(position) {
    this.clsVerifyProduct.Userlongitude = position.coords.longitude;
    this.clsVerifyProduct.Userlatitude = position.coords.latitude;
    this.reverseGeocodeLatLngAndVerifyProduct();
  }
  //to get location
  reverseGeocodeLatLngAndVerifyProduct() {
    // tslint:disable-next-line: max-line-length
    this.reverseGeoCode(
      this.clsVerifyProduct.Usrlatitude,
      this.clsVerifyProduct.Usrlongitude
    ).then(
      (response) => {
        const res = response;
        this.clsVerifyProduct.UserCity = res.address.state_district;
        this.clsVerifyProduct.UserState = res.address.state;
        this.clsVerifyProduct.UserCntry = res.address.country;
        this.clsVerifyProduct.UserZip = res.address.postcode;
        this.clsVerifyProduct.UserAddress = res.display_name;
        this.setState({
          Location: res.display_name,
        });
        this.GetVerifyProduct(this.clsVerifyProduct);
      },
      (error) => {
        // alert(JSON.stringify(error))
        this.GetVerifyProduct(this.clsVerifyProduct);
      }
    );
  }

  render() {
    return (
      <div className="pa-outer row">
        <div className="container-fluid p-0">
          <div
            className="text-center col-md-12"
            style={{ borderBottom: "1px solid #ccc", paddingBottom: "10px" }}
          >
            {/* <div className="logo-wraper">
              <div className="logo-c">
                <img src={this.props.ProductAuthenticationData.CompanyLogo} />
              </div>
            </div> */}
            <img src={logo} />
          </div>{" "}
          <div className="text-center mt-3">
            <h5>Please Download and Use the H2WP App for Scanning</h5>
            <div
              className="text-center"
              style={{
                width: "235px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <a
                style={{ display: "block" }}
                href="https://play.google.com/store/apps/details?id=com.hmsipart&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              >
                <img
                  style={{ width: "235px", height: "auto" }}
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
            </div>

            <img style={{ width: "235px", height: "auto" }} src={appHome}></img>
          </div>
        </div>
        {this.props.ProductAuthenticationData != null ? (
          <div className="container-fluid p-0">
            {(this.props.ProductAuthenticationData.GenuineAudio != null) ===
            true ? (
              <audio
                controls
                autoPlay
                src={this.props.ProductAuthenticationData.GenuineAudio}
                id="pm3paly"
                hidden
              />
            ) : (
              // <div>
              //     <audio controls autoplay >
              //         <source src={this.props.ProductAuthenticationData.GenuineAudio} type="audio/mpeg" />
              //     </audio>
              // </div>
              ""
            )}
            <div
              className="text-center col-md-12"
              style={{ borderBottom: "1px solid #ccc", paddingBottom: "10px" }}
            >
              {/* <div className="logo-wraper">
                                <div className="logo-c">
                                    <img src={this.props.ProductAuthenticationData.CompanyLogo} /></div>
                            </div> */}
              <img src={logo} />
            </div>{" "}
            <div className="success-message">
              {this.props.ProductAuthenticationData.ResponseCode == "200" ? (
                <div className="text-center p-3">
                  {" "}
                  <img src={sucessIcon} className="mb-3" />
                  <h2 className="mb-4">
                    Thanks for buying genuine Honda parts.
                  </h2>
                  <h2>असली होंडा पार्ट खरीदने के लिए धन्यवाद |</h2>{" "}
                </div>
              ) : this.props.ProductAuthenticationData.ResponseCode == "400" ? (
                <div className="text-center p-3">
                  {" "}
                  <img src={sucessIcon} className="mb-3" />
                  <h2 className="mb-4">
                    Thanks for buying genuine Honda parts.
                    {/* The part you have scanned is already verified. */}
                  </h2>
                  <h2>
                    असली होंडा पार्ट खरीदने के लिए धन्यवाद |
                    {/* आपके द्वारा स्कैन किया गया होंडा पार्ट पहले ही सत्यापित हो
                    चुका है | */}
                  </h2>{" "}
                </div>
              ) : this.props.ProductAuthenticationData.ResponseCode == "-1" ? (
                <div className="text-center p-3">
                  {" "}
                  <img src={fakeIcon} className="mb-3" />
                  <h2 className="mb-4">
                    Seems like this part may be counterfeited or fake.{" "}
                  </h2>
                  <h2>खरीदा गया ये पार्ट नकली हो सकता है |</h2>{" "}
                </div>
              ) : (
                ""
              )}
            </div>
            {/* <div className="product-detail">
                            <h3>Product Name</h3>
                            {this.state.ratingBox ? <div className="rating-wraper">
                                <Rating value={this.state.rating} onChange={(e) => this.setState({ rating: e.value })} />
                            </div> : null}
                            <span onClick={() => this.setState({ showDialog: true })}>
                                <img src={reviewIcon} /> Feedback
                            </span>
                            <span onClick={() => this.setState({ shareBox: !this.state.shareBox })}>
                                <img src={shareIcon} />
                                share
                            </span>
                            {this.state.shareBox ? <div className="rating-wraper">
                                <i className="fa fa-facebook-square"></i>
                                <i className="fa fa-twitter-square"></i>
                                <i className="fa fa-youtube-square"></i>
                                <i className="fa fa-google-plus-square"></i>
                            </div> : null}
                        </div>
                        <div className="row mt-4 ml-5">
                            {this.props.ProductAuthenticationData.ProductSpecification == (undefined || null) ? null : this.props.ProductAuthenticationData.ProductSpecification.map((x, i) => {
                                return (
                                    <>
                                        <div className="col-md-4 pb-3">{x.Key}:</div>
                                        <div className="col-md-8 pb-3">{x.Value}</div>
                                    </>
                                )

                            })}
                        </div> */}
          </div>
        ) : (
          ""
        )}
        ;
        {/* <Dialog header="Feedback Form" modal dismissableMask={true} visible={this.state.showDialog} style={{ width: '50vw' }} onHide={this.onHide}>

                    <form onSubmit={this.submitForm}>

                        <div className="row">

                            <div className="col-md-6 pt-2">
                                <TextField id="standard-basic" size="small" onChange={this.handleChange} variant="outlined"
                                    name="Name" value={this.state.Name} className="fullwidth"
                                    label="Name*" />
                                {this.state.formError.Name !== '' ?
                                    <div className='error'>{this.state.formError.Name}</div> : ''}
                            </div>

                            <div className="col-md-6 pt-2">
                                <TextField id="standard-basic" size="small" onChange={this.handleChange} variant="outlined"
                                    name="email" value={this.state.email} className="fullwidth"
                                    label="Email*" />
                                {this.state.formError.email !== '' ?
                                    <div className='error'>{this.state.formError.email}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-2">
                                <TextField id="standard-basic" size="small" onChange={this.handleChange} variant="outlined"
                                    name="MobileNo" value={this.state.MobileNo} className="fullwidth"
                                    label="Mobile number*" />
                                {this.state.formError.MobileNo !== '' ?
                                    <div className='error'>{this.state.formError.MobileNo}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-2">
                                <TextField id="standard-basic" size="small" onChange={this.handleChange} variant="outlined"
                                    name="UserFeedback" value={this.state.UserFeedback} className="fullwidth"
                                    label="Feedback*" />
                                {this.state.formError.UserFeedback !== '' ?
                                    <div className='error'>{this.state.formError.UserFeedback}</div> : ''}
                            </div>
                            <div className="col-md-12 mt-3 text-center">
                                <Button label="Submit" icon="pi pi-check" />
                            </div>
                        </div>
                    </form>

                </Dialog>
             */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("state.productAuthentication.items", state.productAuthentication.items);
  //console.log("state.productAuthentication.items[0]", (state.productAuthentication.items && state.productAuthentication.items.length) > 0 ? state.productAuthentication.items[0]  : null);
  return {
    ProductAuthenticationData:
      (state.productAuthentication.items &&
        state.productAuthentication.items.length) > 0
        ? state.productAuthentication.items[0]
        : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyProduct: (productAuth) =>
      dispatch(productAuthenticationActions.verifyProduct(productAuth)),
    UpdateFeedback: (feedback) =>
      dispatch(productAuthenticationActions.UpdateFeedback(feedback)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductAuthentication);

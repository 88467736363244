import { mappingRptConstants } from '../constants';

export function mappingRpt(state = {}, action) {
    switch (action.type) {
        case mappingRptConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case mappingRptConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case mappingRptConstants.GET_ALL_Out_SUCCESS:
            return {
                ...state,
                OUT: action.datas
            };
        case mappingRptConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case mappingRptConstants.GETEXPORT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case mappingRptConstants.GETEXPORT_SUCCESS:
            return {
                ...state,
                items: action.datas,
                exportitems: action.datas,
                loading: false
            };
        case mappingRptConstants.GETEXPORT_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case mappingRptConstants.LODING_REQUEST:
            return {
                ...state,
                loading: action.isbool
            };
        default:
            return state
    }


}
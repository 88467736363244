import { authHeader, handleResponse } from '../helpers';
export const issueNoService = {
    createIssueNo,
    fetchIssueNoData,
    fetchFromNumbersData,
    fetchBatchNumberDDL,
    fetchPlantDLL,
    fetchBatchDetails
};

function createIssueNo(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}IssueNo/CreateIssueNumber`, requestOptions)
        .then(handleResponse);
}

function fetchIssueNoData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}IssueNo/GetIssueNumbers`, requestOptions)
        .then(handleResponse);
}

function fetchFromNumbersData() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}IssueNo/GetFromNumbers`, requestOptions)
        .then(handleResponse);
}

function fetchBatchNumberDDL() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}IssueNo/GetBatchNumberDLL`, requestOptions)
        .then(handleResponse);
}

function fetchPlantDLL() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}IssueNo/GetPlantDLL`, requestOptions)
        .then(handleResponse);
}

function fetchBatchDetails(batchNumber) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}IssueNo/GetBatchDetails/${batchNumber}`, requestOptions)
        .then(handleResponse);
}








import { authHeader, handleResponse } from '../helpers';
export const productConfigurationService = {
    updateProductConfig,
    fetchProductConfigData,
    sp_getconfigDDL

};

function updateProductConfig(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}ProductConfiguration/UpdateProductConfiguration`, requestOptions)
        .then(handleResponse);
}


function fetchProductConfigData(ProductId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}ProductConfiguration/spGetConfigrationData/${ProductId}`, requestOptions)
    .then(handleResponse);
}

function sp_getconfigDDL(productId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}ProductConfiguration/getconfigDDL/${productId}`, requestOptions)
    .then(handleResponse);
}
import { loginService } from '../services';
import { loginConstants } from '../constants';
import { alertActions } from '.';
import { history } from '../helpers';

export const loginActions = {
    checkUser
    
};


function checkUser(data) {
    return dispatch => {
        dispatch(request(data));

        loginService.checkUser(data).then(
            response => {
                ;
                // dispatch(success(response.ResponseData));
                //history.push('/');
                if (response && response.ResponseCode) {
                    // response.ResponseCode == "200" ? dispatch(alertActions.success(response.ResponseMessage))
                    //     : response.ResponseCode == "400" ? dispatch(alertActions.warning(response.ResponseMessage))
                    //     : dispatch(alertActions.error(response.ResponseMessage))

                    if(response.ResponseCode=="200"){
                        sessionStorage.setItem("LoggedInUser", JSON.stringify({ login: true }));
                        sessionStorage.setItem("UserData", response.ResponseData[0].UserId);
                        sessionStorage.setItem("UserRole", response.ResponseData[0].RoleId);
                        sessionStorage.setItem("UserNameData", response.ResponseData[0].UserName);
                        sessionStorage.setItem("UserEmailData", response.ResponseData[0].Email);
                        sessionStorage.setItem("UserMobileData", response.ResponseData[0].MobileNo);
                        sessionStorage.setItem("CompanyData", response.ResponseData[0].CompanyId);
                        window.location = '/dashboard'

                    }
                    else{

                     dispatch(alertActions.error(response.ResponseMessage));

                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: loginConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: loginConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: loginConstants.CREATE_FAILURE, error } }
};






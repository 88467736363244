import { authHeader, handleResponse } from '../helpers';
export const dashboardService = {
    fetchData,
   
};

function fetchData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}Dashboard/GetDashboardData`, requestOptions)
    .then(handleResponse);
}


import { productAuthenticationService } from '../services';
import { productAuthenticationConstants } from '../constants';
import { alertActions } from '.';
import { history } from '../helpers';

export const productAuthenticationActions = {
    verifyProduct,
    UpdateFeedback
};

function verifyProduct(data) {
    
    return dispatch => {
        dispatch(request(data));
        
        productAuthenticationService.verifyProduct(data).then(
            response => {
                dispatch(success(response.ResponseData));
                //history.push('/');
                if (response && response.ResponseCode) {
                    // response.ResponseCode == "200" ? dispatch(alertActions.success(response.ResponseMessage))
                    //     : response.ResponseCode == "400" ? dispatch(alertActions.warning(response.ResponseMessage))
                    //         : dispatch(alertActions.error(response.ResponseMessage))
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: productAuthenticationConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: productAuthenticationConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: productAuthenticationConstants.CREATE_FAILURE, error } }
};

function UpdateFeedback(data) {
    
    return dispatch => {
        
        productAuthenticationService.UpdateFeedback(data).then(
            response => {               
                if (response && response.ResponseCode) {
                    response.ResponseCode == "200" ? dispatch(alertActions.success(response.ResponseMessage))
                        : response.ResponseCode == "400" ? dispatch(alertActions.warning(response.ResponseMessage))
                            : dispatch(alertActions.error(response.ResponseMessage))
                }
            },
            error => {
                dispatch(alertActions.error(error.toString()));
            }
        );
    };
};







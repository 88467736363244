import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TextField from '@material-ui/core/TextField';
import { Calendar } from 'primereact/calendar';
import { alertActions, NumberTrackingRptActions } from "../../actions";
import { connect } from 'react-redux';
import tableRequest from '../../models/tableRequest.js';
import { Toast } from 'primereact/toast';
import { CSVLink } from "react-csv";
import { TreeTable } from 'primereact/treetable';
import { Button } from 'primereact/button';
class NumberTrackingRpt extends Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.FilterData = this.FilterData.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.state = {
            loading: false,
            node: [],
            FromDate: new Date(),
            ToDate: new Date(),
            first: 0,
            rows: 5,
            totalRecords: 0,
            globalFilter: null,
            visible: false,
            sortOrder: '',
            datalist: [],
        }
    }
    componentDidMount() {
        var date = new Date();
        date.setDate(date.getDate() - 7);
        this.tblResponse.FromDate = date.toLocaleDateString();
        this.tblResponse.ToDate = new Date().toLocaleDateString();
        this.setState({
            FromDate: date,
            ToDate: new Date()
        });
        this.props.fetchApi(this.tblResponse);
    }
    GetFeedback() {
        let ToDt = new Date();
        let FrmDt = new Date();
        FrmDt.setDate(ToDt.getDate() - 7);

        const myFmDt = this.getCurrentDateYYYYMMDD(FrmDt);
        const myToDt = this.getCurrentDateYYYYMMDD(ToDt);

        this.tblResponse.FromDate = myFmDt;
        this.tblResponse.ToDate = myToDt;
        this.props.fetchApi(this.tblResponse);
    }

    FilterData() {
        
        this.tblResponse.FromDate = this.state.FromDate;
        this.tblResponse.ToDate = this.state.ToDate;

        this.props.fetchApi(this.tblResponse);
    }

    getCurrentDateYYYYMMDD(dt) {
        var dd = String(dt.getDate()).padStart(2, '0');
        var mm = String(dt.getMonth() + 1).padStart(2, '0');
        var yyyy = dt.getFullYear();

        dt = yyyy + '/' + mm + '/' + dd;
        return dt;
    }
    handleChange(event) {
        const { name, value } = event.target;
        switch (name) {
            case 'FromDate':
                if (value === null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please select from date.', life: 3000 });
                    // this.refs.Messchild.showError("Please select from date.");
                    return;
                }
                else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
                    this.formIsValid = false;
                    // this.refs.Messchild.showError("Please enter only date.");
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please enter only date.', life: 3000 });
                    return;
                }
                else if ((value instanceof Date) && (this.state.ToDate instanceof Date)) {
                    const startDate = value;
                    const endDate = this.state.ToDate;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        // this.refs.Messchild.showError("From date must be less than to date.");
                        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'From date must be less than to date.', life: 3000 });
                        return;
                    }
                }

                break;
            case 'ToDate':
                if (value === null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please select to date.', life: 3000 });
                    // this.refs.Messchild.showError("Please select to date.");
                    return;
                }
                else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
                    this.formIsValid = false;
                    // this.refs.Messchild.showError("Please enter only date.");
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please enter only date.', life: 3000 });
                    return;
                }
                else if ((value instanceof Date) && (this.state.FromDate instanceof Date)) {
                    const startDate = this.state.FromDate;
                    const endDate = value;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'To date must be greater than from date.', life: 3000 });
                        // this.refs.Messchild.showError("To date must be greater than from date.");
                        return;
                    }
                }

                break;
            default:
                break;
        }

        this.setState({ [name]: value });
    }

    //to lazy load
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
        this.tblResponse.FromDate = this.state.FromDate;
        this.tblResponse.ToDate = this.state.ToDate;
    }

    onPage(event) {
        this.setState({
            loading: true
        });

        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
        }, 250);
    }

    //to search
    searchFilter(e) {
        // this.setState({
        //     loading: true
        // });
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);
    }

    //to sort
    onSort(event) {
        ;
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.multiSortMeta[0].field === "CreatedDate" ? "CreatedDt" : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';//this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);
    }

    render() {
        let nodes = [
            {
                "key": "0",
                "data": {
                    "poNo": "PO-001",
                    "supplier": "Holostik",
                    "qty": "3000",
                    "useQty": "1000",
                    "remainingQty": "2000",
                    "poDate": "17-08-2021",
                },
                "children": [
                    {
                        "key": "0-0",
                        "data": {
                            "batchNo": "Bt-001",
                            "qty": "2000",
                            "issueQrCode": "100",
                            "remaningQrCode": "1900",
                            "batchDate": "17-08-2021",
                        },
                        "children": [
                            {
                                "key": "0-0-0",
                                "data": {
                                    "qtyFrom": "10",
                                    "qtyTo": "100"
                                }
                            }

                        ]
                    }
                ]
            },
        ]
        return (
            <div className="datatable-filter-demo">
                <Toast ref={(el) => this.toast = el} />
                <div className="row mb-3">
                    <div className="col-md-3">
                        <h4>Number Tracking Report</h4>
                    </div>
                    <div className="col-md-9">
                        <div className="row text-right">
                            <div className="col-md-3"></div>
                            <div className="col-md-2">
                                <Calendar placeholder="From Date" name="FromDate" showIcon="true" value={this.state.FromDate} onChange={this.handleChange}></Calendar></div>
                            <div className="col-md-2">
                                <Calendar placeholder="To Date" name="ToDate" showIcon="true" value={this.state.ToDate} onChange={this.handleChange}></Calendar>
                            </div>
                            <div className="col-md-2 text-left p-0">
                                <button type="button" onClick={this.FilterData} className="btn mr-2 btn-primary p-2 pl-3 pr-3">Submit</button>

                                <CSVLink data={this.props.List} headers={this.state.headers} filename={"Feedback report.csv"}
                                    // className="btn btn-primary ml-2"
                                    target="_blank"><button className="btn btn-success p-2 pr-3 pl-3">Export</button></CSVLink>
                            </div>
                            <div className="col-md-3">
                                <TextField id="standard-basic" size="small" variant="outlined" className="fullwidth" label="Search" onInput={this.searchFilter} />
                            </div>

                        </div>

                    </div>
                </div>
                <div className="card">
                    {/* <DataTable value={this.props.NumberTrackingList}
                        scrollable responsive={true}
                        globalFilter={this.state.globalFilter}
                        emptyMessage="No data found." sortMode="multiple" paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[5, 10, 20,100,parseInt(this.state.totalRecords)]}
                        // totalRecords={this.state.totalRecords}
                        // lazy={true} first={this.state.first}
                        onPage={this.onPage} onSort={this.onSort} loading={this.state.loading} ref={(el) => { this.dt = el; }}>

                        <Column field="fromNo" disabled header="From No." sortable></Column>
                        <Column field="ToNo" disabled header="To No." sortable></Column>
                        <Column field="IssueQty" disabled header="Issue Quantity" sortable></Column>
                        <Column field="BatchNo" disabled header="Batch No" sortable></Column>
                        <Column field="PlantName" disabled header="Plant Name " sortable></Column>
                        <Column field="ProductName" disabled header="Product Name" sortable></Column>

                    </DataTable> */}
                    {/* <TreeTable value={nodes}>
                    <Column field="poNo" header="PO No." expander></Column>
                        <Column field="supplier" header="Supplier"></Column>
                        <Column field="qty" header="Quantity"></Column>
                        <Column field="useQty" header="Use Quantity"></Column>
                        <Column field="remainingQty" header="Remaining Quantity"></Column>
                        <Column field="poDate" header="PO Date"></Column>
        </TreeTable> */}
                    <table width="100%" className="table table-striped">
                        <tr>
                            <th width="50"></th>
                            <th>PO No.</th>
                            <th>Supplier</th>
                            <th>Quantity</th>
                            <th>Use Quantity</th>
                            <th>Remaining Quantity</th>
                            <th>PO Date</th>
                        </tr>
                        <tr id="parents">
                            <td width="50"><Button className="pl-4 pr-4" data-toggle="collapse" data-target="#parents-element" icon="pi pi-angle-down" /></td>
                            <td>PO-001</td>
                            <td>Holostik</td>
                            <td>5000</td>
                            <td>4000</td>
                            <td>1000</td>
                            <td>17-08-2021</td>
                        </tr>
                        <tr>
                            <td colSpan="7" className="pl-5 pt-0 collapse" id="parents-element">
                                <table width="100%" className="table table-striped">
                                    <tr id="child">
                                        <th width="50"></th>
                                        <th>Batch No.</th>
                                        <th>Quantity</th>
                                        <th>Issue QR Code</th>
                                        <th>Remaining QR Code</th>
                                        <th>Batch Date</th>
                                    </tr>

                                    <tr>
                                        <td width="50"><Button data-toggle="collapse" data-target="#child-element" className="pl-4 pr-4" icon="pi pi-angle-down" /></td>
                                        <td>BT-001</td>
                                        <td>1000</td>
                                        <td>800</td>
                                        <td>200</td>
                                        <td>17-08-2021</td>
                                    </tr>
                                    <tr  >
                                        <td colSpan="6" className="pt-0 collapse" style={{ paddingLeft: '55px' }} id="child-element">
                                            <table width="100%" className="table table-striped" style={{ width: '60%' }} >
                                                <tr>
                                                    <th>Quantity From</th>
                                                    <th>Quantity To</th>
                                                    <th>Quantity</th>
                                                    <th>Plant</th>
                                                </tr>
                                                <tr>
                                                    <td>001</td>
                                                    <td>800</td>
                                                    <td>800</td>
                                                    <td>Tapukhera Plant3</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50"><Button data-toggle="collapse" data-target="#child-element1" className="pl-4 pr-4" icon="pi pi-angle-down" /></td>
                                        <td>BT-002</td>
                                        <td>3000</td>
                                        <td>3000</td>
                                        <td>0</td>
                                        <td>17-08-2021</td>
                                    </tr>
                                    <tr  >
                                        <td colSpan="6" className="pt-0 collapse" style={{ paddingLeft: '55px' }} id="child-element1">
                                            <table width="100%" className="table table-striped" style={{ width: '60%' }} >
                                                <tr>

                                                    <th>Quantity From</th>
                                                    <th>Quantity To</th>
                                                    <th>Quantity</th>
                                                    <th>Plant</th>
                                                </tr>
                                                <tr>
                                                    <td>1001</td>
                                                    <td>3000</td>
                                                    <td>3000</td>
                                                    <td>Tapukhera Plant1</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                    </table>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        NumberTrackingList: state.NumberTrackingRpt.items,
        List: state.FeedbackRpt.items == undefined ? [] : state.FeedbackRpt.items
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (obj) => {
            dispatch(NumberTrackingRptActions.getAll(obj))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberTrackingRpt)

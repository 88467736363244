import { authHeader, handleResponse } from '../helpers';
export const demappingService = {
    createDemapping,
    fetchFromNumbersData

};

function createDemapping(data) {  

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}DeMapping/CreateDemapping`, requestOptions)
    .then(handleResponse);
}

function fetchFromNumbersData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}IssueNo/GetFromNumbers`, requestOptions)
    .then(handleResponse);
}








import { menuService } from '../services';
import { menuConstants } from '../constants';
import { alertActions } from '.';
import { history } from '../helpers';
 
export const menuActions = {
    getMenu
};
 
function getMenu(roleId) {
    return dispatch => {
        dispatch(request());
 
        menuService.fetchMenuData(roleId).then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };
 
    function request() { return { type: menuConstants.GETALL_REQUEST } }
    function success(datas) { return { type: menuConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: menuConstants.GETALL_FAILURE, error } }
}
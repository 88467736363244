import { productConfigurationConstants } from '../constants';

export function productConfiguration(state = {}, action) {
    switch (action.type) {
        case productConfigurationConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case productConfigurationConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case productConfigurationConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case productConfigurationConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            };
        case productConfigurationConstants.CREATE_SUCCESS:
            return {
                ...state,
                items: [action.data, ...state.items]
            };
        case productConfigurationConstants.CREATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case productConfigurationConstants.UPDATE_REQUEST:
            return {
                ...state,
                items: state.items
            };
        case productConfigurationConstants.UPDATE_SUCCESS:
            return {
                ...state,
                items: null,
                isOpen: false,
                roleId: 0
            };
        case productConfigurationConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        
        case productConfigurationConstants.DELETE_REQUEST:
            return {
                ...state,
                deleting: true
            };
        case productConfigurationConstants.DELETE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.productAuthenticationId === action.data ? {
                        CreatedBy: item.CreatedBy,
                        CreatedDt: item.CreatedDt,
                        productAuthenticationId: item.productAuthenticationId,
                        productName: item.productName,
                        UpdatedBy: item.UpdatedBy,
                        UpdatedDt: item.UpdatedDt,
                        FromNo:item.FromNo,
                        ToNo:item.ToNo,
                        Qty:item.Qty
                    } : item
                )
            };


        case productConfigurationConstants.GETCONFIGDDL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case productConfigurationConstants.GETCONFIGDDL_SUCCESS:
            return {
                ...state,
                configddl: action.datas
            };
        case productConfigurationConstants.GETCONFIGDDL_FAILURE:
            return {
                ...state,
                error: action.error
            };


        case productConfigurationConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case productConfigurationConstants.RESET_REQUEST:
            return {
                ...state,
                reseting: action.isbool
            };
        default:
            return state
    }
}
import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TextField from '@material-ui/core/TextField';
import { Calendar } from 'primereact/calendar';
import { Button } from "primereact/button";
import { alertActions, QRDetailsRptActions } from "../../actions/index.js";
import { connect } from 'react-redux';
import tableRequest from '../../models/tableRequest.js';
import { Toast } from 'primereact/toast';
import { CSVLink } from "react-csv";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { TabMenu } from 'primereact/tabmenu';
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";

class QRDetailsRpt extends Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        this.exportCSV = this.exportCSV.bind(this);
        this.csvLink = React.createRef();

        this.state = {
            loading: false,
            FromDate: new Date(),
            ToDate: new Date(),
            first: 0,
            rows: 10,
            totalRecords: 0,
            globalFilter: null,
            visible: false,
            sortOrder: '',
            datalist: [],
            headers: [
                { label: "QR Data", key: "QrData" },
                { label: "Name", key: "UserName" },
                { label: "Mobile Number", key: "MobileNo" },
                { label: "Part Image 1", key: "PartImageURL1" },
                { label: "Part Image 2", key: "PartImageURL2" },
                { label: "Feedback Updated On", key: "FeedbackUpdatedOn" },
                { label: "Shop Name", key: "ShopName" },
                { label: "City", key: "UserCity" },
                { label: "State", key: "UserState" },
                { label: "Address", key: "UserAddress" },
                { label: "Verification Mode", key: "VerificationMode" },
                { label: "Verified Date", key: "VerifiedDate" },
            ],
            items: [
                { label: 'Honda Outbound', icon: 'pi pi-fw pi-arrow-up', command: () => { this.setState({ activeItem: 'home' }); } },
                { label: 'Holostik Issue Details', icon: 'pi pi-fw pi-arrow-down', command: () => { this.setState({ activeItem: 'settings' }); } },
                { label: 'Scan Log', icon: 'pi pi-fw pi-align-center', command: () => { this.setState({ activeItem: 'contact' }); } }
            ],
            activeItem: null // initially no active item
        }
    }
    componentDidMount() {
        var date = new Date();
        date.setDate(date.getDate() - 7);
        this.tblResponse.FromDate = date.toLocaleDateString();
        this.tblResponse.ToDate = new Date().toLocaleDateString();
        this.tblResponse.SortColumn = "VerifiedDate";
        this.setState({
            FromDate: date,
            ToDate: new Date()
        });

    }
    GetFeedback() {
        this.props.lodingData(true);
        let ToDt = new Date();
        let FrmDt = new Date();
        FrmDt.setDate(ToDt.getDate() - 7);

        const myFmDt = this.getCurrentDateYYYYMMDD(FrmDt);
        const myToDt = this.getCurrentDateYYYYMMDD(ToDt);

        this.tblResponse.FromDate = myFmDt.toLocaleDateString();
        this.tblResponse.ToDate = myToDt.toLocaleDateString();

    }



    getCurrentDateYYYYMMDD(dt) {
        var dd = String(dt.getDate()).padStart(2, '0');
        var mm = String(dt.getMonth() + 1).padStart(2, '0');
        var yyyy = dt.getFullYear();

        dt = yyyy + '/' + mm + '/' + dd;
        return dt;
    }










    onModeChange(e) {
        this.tblResponse.VM = e.target.value;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.Data !== this.props.Data) {
            // this.props.myProp has a different value
            if (this.props.Data) {
                var e = this.props.Data;
            } else {
                this.setState({
                    totalRecords: 0
                });
            }
        }
    }

    exportCSV() {
        debugger
        this.tblResponse.FromDate = this.state.FromDate;
        this.tblResponse.ToDate = this.state.ToDate;
        this.tblResponse.First = -1;
        this.tblResponse.PageSize = -1;
        this.props.exportData(this.tblResponse);
        setTimeout(() => {
            this.csvLink.current.element.click();
        }, 5000);
    }

    renderContent() {
        // ramesh
        switch (this.state.activeItem) {
            case 'home':
                return <div>
                    <div className="card">
                        <DataTable value={this.props.Data?.OutbountData}
                            scrollable responsive={true}
                            globalFilter={this.state.globalFilter}
                            emptyMessage="No data found." sortMode="multiple"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[10, 25, 50, 100, parseInt(this.state.totalRecords)]}
                            totalRecords={this.state.totalRecords}
                            loading={this.props.loding}
                            lazy={true} first={this.state.first}
                            onPage={this.onPage} onSort={this.onSort} ref={(el) => { this.dt = el; }}>
                            <Column field="Serial" disabled header="Serial" sortable></Column>
                            <Column field="UPI_Codes" disabled header="UPI Codes" sortable></Column>
                            <Column field="QRLink" disabled header="QR Link" sortable></Column>
                            <Column field="Part_No" disabled header="Part No" sortable></Column>
                            <Column field="Used" disabled header="Used" sortable></Column>
                            <Column field="Time_of_Usage" disabled header="Time of Usage" sortable></Column>
                            <Column field="Label" disabled header="Label" sortable></Column>
                            <Column field="Printer" header="Printer" sortable></Column>
                            <Column field="UserID" header="UserID" sortable></Column>
                            <Column field="Type" header="Type" sortable></Column>
                        </DataTable>
                    </div>
                </div>;
            case 'settings':
                return <div>
                    <div className="card">
                        <DataTable value={this.props.Data?.IssueData}
                            scrollable responsive={true}
                            globalFilter={this.state.globalFilter}
                            emptyMessage="No data found." sortMode="multiple"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[10, 25, 50, 100, parseInt(this.state.totalRecords)]}
                            totalRecords={this.state.totalRecords}
                            loading={this.props.loding}
                            lazy={true} first={this.state.first}
                            onPage={this.onPage} onSort={this.onSort} ref={(el) => { this.dt = el; }}>
                            <Column field="FromNo" disabled header="FromNo" sortable></Column>
                            <Column field="ToNo" disabled header="To No" sortable></Column>
                            <Column field="IssueQty" disabled header="IssueQty" sortable></Column>
                            <Column field="CreatedDate" disabled header="CreatedDate" sortable></Column>
                        </DataTable>
                    </div>
                </div>;
            case 'contact':
                return <div>

                    <div className="card">
                        <DataTable value={this.props.Data?.ScanLogs}
                            scrollable responsive={true}
                            globalFilter={this.state.globalFilter}
                            emptyMessage="No data found." sortMode="multiple"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[10, 25, 50, 100, parseInt(this.state.totalRecords)]}
                            totalRecords={this.state.totalRecords}
                            loading={this.props.loding}
                            lazy={true} first={this.state.first}
                            onPage={this.onPage} onSort={this.onSort} ref={(el) => { this.dt = el; }}>
                            <Column field="QrData" disabled header="QrData" sortable></Column>
                            <Column field="SeqNo" disabled header="Seq No" sortable></Column>
                            <Column field="VerifiedDate" disabled header="Verified Date" sortable></Column>
                            <Column field="StatusResult" disabled header="Status Result" sortable></Column>


                        </DataTable>
                    </div>

                </div>;
            default:
                return <div>
                    <div className="card">
                        <DataTable value={this.props.Data?.OutbountData}
                            scrollable responsive={true}
                            globalFilter={this.state.globalFilter}
                            emptyMessage="No data found." sortMode="multiple"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[10, 25, 50, 100, parseInt(this.state.totalRecords)]}
                            totalRecords={this.state.totalRecords}
                            loading={this.props.loding}
                            lazy={true} first={this.state.first}
                            onPage={this.onPage} onSort={this.onSort} ref={(el) => { this.dt = el; }}>
                            <Column field="Serial" disabled header="Serial" sortable></Column>
                            <Column field="UPI_Codes" disabled header="UPI Codes" sortable></Column>
                            <Column field="QRLink" disabled header="QR Link" sortable></Column>
                            <Column field="Part_No" disabled header="Part No" sortable></Column>
                            <Column field="Used" disabled header="Used" sortable></Column>
                            <Column field="Time_of_Usage" disabled header="Time of Usage" sortable></Column>
                            <Column field="Label" disabled header="Label" sortable></Column>
                            <Column field="Printer" header="Printer" sortable></Column>
                            <Column field="UserID" header="UserID" sortable></Column>
                            <Column field="Type" header="Type" sortable></Column>
                        </DataTable>
                    </div>
                </div>;
        }
    }

    handleInputChange = (event) => {
        // Dispatch action to set qrCodeData in Redux store
        const qrdata = event.target.value;
        this.props.setQRCodeData(qrdata);
    }
    render() {
        return (
            <div className="datatable-filter-demo">
                <Toast ref={(el) => this.toast = el} />
                <div className="row mb-3">
                    <div className="col-md-3">
                        <h4>QR Details Report</h4>
                    </div>
                    <div className="col-md-9">
                        <div className="row text-right">
                            <div className="col-md-9">
                                <TextField
                                    id="standard-basic"
                                    size="small"
                                    variant="outlined"
                                    className="fullwidth"
                                    label="QR Code / UPI Code"
                                    onChange={this.handleInputChange}
                                    value={this.props.qrCodeData} // Get qrCodeData from Redux store
                                />
                            </div>





                            <div className="col-md-3 text-left">
                                <button type="button" onClick={this.handleSubmit} className="btn btn-primary p-2 pl-3 pr-3 mr-2">Submit</button>

                                <button onClick={this.exportCSV} className="btn btn-success p-2 pl-3 pr-3">Export</button>
                            </div>


                        </div>

                    </div>
                </div>

                <div className="text-center">
                    <ExcelFile
                        filename={"QR Details Report"}
                        element={<Button hidden ref={this.csvLink} />}
                    >
                        <ExcelSheet data={this.props.Data?.OutbountData} name={"QR Details Report"}>
                            <ExcelColumn label="Serial" value="Serial" />
                            <ExcelColumn label="UPI Code" value="UPI_Codes" />
                            <ExcelColumn label="QRLink" value="QRLink" />
                            <ExcelColumn label="Part No" value="Part_No" />
                            <ExcelColumn label="Used" value="Used" />
                            <ExcelColumn label="Time Of Usage" value="Time_of_Usage" />
                            <ExcelColumn label="Label" value="Label" />
                            <ExcelColumn label="Printer" value="Printer" />
                            <ExcelColumn label="UserID" value="UserID" />
                            <ExcelColumn label="Type" value="Type" />
                        </ExcelSheet>
                        <ExcelSheet data={this.props.Data?.IssueData} name={"IssueData"}>
                            <ExcelColumn label="FromNo" value="FromNo" />
                            <ExcelColumn label="ToNo" value="ToNo" />
                            <ExcelColumn label="IssueQty" value="IssueQty" />
                            <ExcelColumn label="CreatedDate" value="CreatedDate" />
                        </ExcelSheet>

                        {/* Sheet 3 */}
                        <ExcelSheet data={this.props.Data?.ScanLogs} name={"ScanLogs"}>
                            <ExcelColumn label="QrData" value="QrData" />
                            <ExcelColumn label="Seq No" value="SeqNo" />
                            <ExcelColumn label="VerifiedDate" value="VerifiedDate" />
                            <ExcelColumn label="StatusResult" value="StatusResult" />

                        </ExcelSheet>
                    </ExcelFile>

                </div>


                {/* <div className="card">
                    <DataTable value={this.props.Data}
                        scrollable responsive={true}
                        globalFilter={this.state.globalFilter}
                        emptyMessage="No data found." sortMode="multiple" paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[10, 25, 50, 100,parseInt(this.state.totalRecords)]}
                        totalRecords={this.state.totalRecords}
                        loading={this.props.loding}
                        lazy={true} first={this.state.first}
                        onPage={this.onPage} onSort={this.onSort} ref={(el) => { this.dt = el; }}>
                        <Column field="QrData" disabled header="QR Data" sortable></Column>
                        <Column field="UserName" disabled header="Name" sortable></Column>
                        <Column field="MobileNo" disabled header="Mobile No." sortable></Column>
                        <Column field="PartImageURL1" body={(data) => {
                            if (data.PartImageURL1 != null) return (<a target='_blank' href={data.PartImageURL1}>View</a>)
                        }} disabled header="Part Image 1" sortable></Column>
                        <Column field="PartImageURL2" body={(data) => {
                            if (data.PartImageURL2 != null) return (<a target='_blank' href={data.PartImageURL2}>View</a>)
                        }} disabled header="Part Image 2" sortable></Column>
                        <Column field="FeedbackUpdatedOn" disabled header="Feedback Updated On" sortable></Column>
                        <Column field="ShopName" disabled header="Shop Name" sortable></Column>
                        <Column field="UserCity" header="City" sortable></Column>
                        <Column field="UserState" header="State" sortable></Column>
                        <Column field="UserAddress" header="Address" sortable></Column>
                        <Column field="VerificationMode" disabled header="Verification Mode" sortable></Column>
                        <Column field="VerifiedDate" header="VerifiedDate" sortable></Column>
                    </DataTable>
                </div> */}

                <div className="card">
                    <TabMenu model={this.state.items} activeItem={this.state.activeItem} />
                    <div className="content">
                        {this.renderContent()}
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        Data: state.QRDetailsRpt?.items,
        List: state.QRDetailsRpt.exportitems == undefined ? [] : state.QRDetailsRpt.exportitems,
        loding: state.QRDetailsRpt.loding
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // fetchProductDetails: (ProductCode) => {
        //     dispatch(fgTraceabilityReportActions.getFGTraceabilityReport(ProductCode))
        // },
        // fetchIntermediateDetails: (ProductCode) => {
        //     dispatch(fgTraceabilityReportActions.getIntermediateTraceability(ProductCode))
        // },
        setQRCodeData: (qrdata) => {
            dispatch(QRDetailsRptActions.getReport(qrdata))
        },
        // fetchApi: (obj) => {
        //     dispatch(QRDetailsRptActions.getAll(obj))
        // },
        exportData: (obj) => dispatch(QRDetailsRptActions.exportData(obj)),
        lodingData: (isLoding) => dispatch(QRDetailsRptActions.lodingData(isLoding))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QRDetailsRpt)
